import request from '@/utils/request'
// 聊天相关接口

// 获取登录二维码
export function getQrcodeUrl(params) {
    return request({
        url: '/web/login/getQrcodeUrl',
        method: 'get',
        params
    })
}


// 检查二维码状态
export function checkQrcode(params) {
    return request({
        url: '/web/login/checkQrcode',
        method: 'get',
        params
    })
}


// 获取用户token
export function getQrcodeToken(params) {
    return request({
        url: '/web/login/getQrcodeToken',
        method: 'get',
        params
    })
}


// 获取用户列表
export function getUserFriendList(params) {
    return request({
        url: '/web/chat/friend/getUserFriendList',
        method: 'get',
        params
    })
}

// 获取群列表
export function getUserGroupList(params) {
    return request({
        url: '/web/chat/group/getUserGroupList',
        method: 'get',
        params
    })
}


// 获取用户详情
export function getFriendInfo(params) {
    return request({
        url: '/web/chat/friend/getFriendInfo',
        method: 'get',
        params
    })
}


// 获取群详情
export function getGroupInfo(params) {
    return request({
        url: '/web/chat/group/getUserGroupInfo',
        method: 'get',
        params
    })
}

// 获取群成员列表
export function getGroupUserList(params) {
    return request({
        url: '/web/chat/group/getGroupUserList',
        method: 'get',
        params
    })
}

// 获取聊天配置
export function getChatConfig(params) {
    return request({
        url: '/web/chat/getChatConfig',
        method: 'get',
        params
    })
}

// 用户聊天敏感词上报
export function dataReport(params) {
    return request({
        url: '/web/chat/dataReport',
        method: 'post',
        params
    })
}

// 聊天退出登录
export function chatLogout(params) {
    return request({
        url: '/web/login/logout',
        method: 'post',
        params
    })
}


// 获取时间戳
export function getServerTime(params) {
    return request({
        url: '/web/common/getServerTime',
        method: 'get',
        params
    })
}

// 获取聊天配置
export function recordLogger(data) {
    return request({
        url: '/web/common/recordLogger',
        method: 'post',
        data
    })
}