import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';  
import i18n from './lang' // Internationalization

Vue.use(ElementUI); 

// 腾讯IM 即时通讯
import TIM from "tim-js-sdk"
import TIMUploadPlugin from 'tim-upload-plugin'
import TIMProfanityFilterPlugin from 'tim-profanity-filter-plugin';

let tim = TIM.create({
    SDKAppID: 1400450227, // 即时通信应用的 SDKAppID,
    logLevel: 0 // dev级别用0，release级别用1，SDK 输出关键信息
})
tim.registerPlugin({ 'tim-profanity-filter-plugin': TIMProfanityFilterPlugin,'tim-upload-plugin': TIMUploadPlugin })//注册COS SDK插件
Vue.prototype.$tim = tim

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);


Vue.config.productionTip = false

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
