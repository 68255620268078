<template>
	<div class="main" ref="main">
		<!-- 内容盒子 -->
		<div class="home pageItem"
			:style="{ backgroundImage: 'url(' + require('@/images/home/bg_shouye1.png') + ')' }">
			<div class="contentBox contentBox2" v-if="isLogin" id="contentBox2" :style="{transform:' scale('+scale+')'}">

				<!-- 已登录 -->
				<div class="chatBox">
					<div class="chatNav">
						<div class="userImgBox" @click="openUserMsg">
							<el-image class="userImg" :src="userInfo.avatar"></el-image>
						</div>
						<div class="listBtn" @click="changeList(1)" :class="listType == 1 ? 'listBtn_A' : ''">
							<div class="unreadCount" v-if="unreadCount>0">{{ unreadCount>99?'99+':unreadCount}}</div>
							<el-image class="listBtnIcon"
								:src="require(`@/images/home/contact/chatList${listType}.png`)"></el-image>
						</div>
						<div class="listBtn" @click="changeList(2)" :class="listType == 2 ? 'listBtn_A' : ''">
							<el-image class="listBtnIcon"
								:src="require(`@/images/home/contact/friendList${listType}.png`)"></el-image>
						</div>
					</div>
					<div class="chatListBox">
						<!-- 搜索输入框 -->
						<div class="seachBox" v-if="seaching">
							<el-input class="seachInput" ref="seachInput" autofocus v-model="seachText"
								@input="startSeach()"></el-input>
							<el-image class="seachInputClose" @click="closeSeach()"
								:src="require(`@/images/home/contact/seach_close.png`)"></el-image>

						</div>
						<!-- 搜索提示框 -->
						<div class="seachBox" @click="gotoSeach" v-else>
							<el-image class="seachIcon" :src="require(`@/images/home/contact/seach.png`)"></el-image>
							<div class="seachTips">{{ $t('chat.shurumingcheng') }}</div>
						</div>
						<!-- 搜索列表 -->
						<div class="seachList" v-if="seaching">
							<!-- 搜索导航 -->
							<div class="seachNav">
								<div class="seachNavBtn" @click="seachNavBtn(index)"
									:class="index == seachNavIndex ? 'seachNavBtn_A' : ''"
									v-for="(item, index) in seachNavItem" :key="index">{{ item }}</div>
							</div>
							<!-- 搜索列表内容 -->

							<!-- 全部 -->
							<div class="seachListBox scrollbarNo" v-if="seachNavIndex == 0">
								<!-- 好友列表 -->
								<div class="seachListC">
									<div class="seachListCTop">
										<div class="seachListCTopL">{{ $t('chat.haoyou') }}</div>
										<div class="seachListCTopR" @click="seachNavBtn(1)">
											{{ $t('chat.gengduo') }}
											<el-image class="seachListMore"
												:src="require(`@/images/home/contact/more.png`)"></el-image>
										</div>
									</div>
									<div class="seachListCBottom" v-if="seachFlist.length == 0 && seachText == ''">
										<div class="seachListRow" @click="openPmsg(item.uid)"
											v-for="(item, index) in fLits.slice(0, 5)" :key="item.uid">
											<el-image class="seachListAvatar" :src="item.avatar">
												<div slot="error" class="image-slot"> 
													<el-image class="seachListAvatar"
													src="https://image.limoapp.net/?data=e7eFdzfaLLG9NQsyBffk7T0timo9ZBsnCdXrN0tFy6EeX3PrD9HU90Y1gcAjEUOXoVCt5mjkAgvmDbwLTyhj6g%3D%3D"
													></el-image>
												</div>
											</el-image>
											<div class="seachListName" v-html="item.nickname"></div>
										</div>
									</div>
									<div class="seachListCBottom" v-else>
										<div class="seachListRow" @click="openPmsg(item.uid)"
											v-for="(item, index) in seachFlist.slice(0, 5)" :key="item.uid">
											<el-image class="seachListAvatar" :src="item.avatar">
												<div slot="error" class="image-slot"> 
													<el-image class="seachListAvatar"
													src="https://image.limoapp.net/?data=e7eFdzfaLLG9NQsyBffk7T0timo9ZBsnCdXrN0tFy6EeX3PrD9HU90Y1gcAjEUOXoVCt5mjkAgvmDbwLTyhj6g%3D%3D"
													></el-image>
												</div>
											</el-image>
											<div class="seachListName" v-html="item.nickname"></div>
										</div>
									</div>
								</div>
								<!-- 群聊列表 -->
								<div class="seachListC">
									<div class="seachListCTop">
										<div class="seachListCTopL">{{ $t('chat.qunliao') }}</div>
										<div class="seachListCTopR" @click="seachNavBtn(2)">
											{{ $t('chat.gengduo') }}
											<el-image class="seachListMore"
												:src="require(`@/images/home/contact/more.png`)"></el-image>
										</div>
									</div>
									<div class="seachListCBottom" v-if="seachGlist.length == 0 && seachText == ''">
										<div class="seachListRow" @click="openQmsg(item.group_id)"
											v-for="(item, index) in gLits.slice(0, 5)" :key="item.group_id">
											<el-image class="seachListAvatar" :src="item.logo">
												<div slot="error" class="image-slot"> 
													<el-image class="seachListAvatar"
													src="https://image.limoapp.net/?data=e7eFdzfaLLG9NQsyBffk7T0timo9ZBsnCdXrN0tFy6EeX3PrD9HU90Y1gcAjEUOXoVCt5mjkAgvmDbwLTyhj6g%3D%3D"
													></el-image>
												</div>
											</el-image>
											<div class="seachListName" v-html="item.groupname"></div>
										</div>
									</div>
									<div class="seachListCBottom" v-else>
										<div class="seachListRow" @click="openQmsg(item.group_id)"
											v-for="(item, index) in seachGlist.slice(0, 5)" :key="item.group_id">
											<el-image class="seachListAvatar" :src="item.logo">
												<div slot="error" class="image-slot"> 
													<el-image class="seachListAvatar"
													src="https://image.limoapp.net/?data=e7eFdzfaLLG9NQsyBffk7T0timo9ZBsnCdXrN0tFy6EeX3PrD9HU90Y1gcAjEUOXoVCt5mjkAgvmDbwLTyhj6g%3D%3D"
													></el-image>
												</div>
											</el-image>
											<div class="seachListName" v-html="item.groupname"></div>
										</div>
									</div>
								</div>
							</div>
							<!-- 搜索好友 -->
							<div class="seachListBox scrollbarNo" v-if="seachNavIndex == 1">
								<div class="seachListCBottom" v-if="seachFlist.length != 0">
									<div class="seachListRow" @click="openPmsg(item.uid)" v-for="(item, index) in seachFlist"
										:key="item.uid">
										<el-image class="seachListAvatar" :src="item.avatar">
											<div slot="error" class="image-slot"> 
												<el-image class="seachListAvatar"
												src="https://image.limoapp.net/?data=e7eFdzfaLLG9NQsyBffk7T0timo9ZBsnCdXrN0tFy6EeX3PrD9HU90Y1gcAjEUOXoVCt5mjkAgvmDbwLTyhj6g%3D%3D"
												></el-image>
											</div>
										</el-image>
										<div class="seachListName" v-html="item.nickname"></div>
									</div>
								</div>
								<div class="noSeach" v-else>
									<el-image class="noSeachImg"
										:src="require(`@/images/home/contact/noSeach.png`)"></el-image>
									<div class="noSeachTips">{{ $t('chat.zanwujieguo') }}</div>
								</div>
							</div>
							<!-- 搜索群聊 -->
							<div class="seachListBox scrollbarNo" v-if="seachNavIndex == 2">
								<div class="seachListCBottom" v-if="seachGlist.length != 0">
									<div class="seachListRow" @click="openQmsg(item.group_id)"
										v-for="(item, index) in seachGlist">
										<el-image class="seachListAvatar" :src="item.logo">
											<div slot="error" class="image-slot"> 
												<el-image class="seachListAvatar"
												src="https://image.limoapp.net/?data=e7eFdzfaLLG9NQsyBffk7T0timo9ZBsnCdXrN0tFy6EeX3PrD9HU90Y1gcAjEUOXoVCt5mjkAgvmDbwLTyhj6g%3D%3D"
												></el-image>
											</div>
										</el-image>
										<div class="seachListName" v-html="item.groupname"></div>
									</div>
								</div>
								<div class="noSeach" v-else>
									<el-image class="noSeachImg"
										:src="require(`@/images/home/contact/noSeach.png`)"></el-image>
									<div class="noSeachTips">{{ $t('chat.zanwujieguo') }}</div>
								</div>
							</div>
						</div>
						<div class="loadingBox scrollbarNo" v-else-if="loading">
							<van-loading type="spinner" size="60px" />
							<div style="color: #ccc;margin-top: 15px;">{{ $t('chat.jiazaizhong') + listRate + "%"}} </div>
						</div>
						<!-- 最近聊天 -->
						<div class="chatList scrollbarNo" v-else-if="listType == 1">
							<div v-for="(item, index) in conversationList2" :key="item.id" v-if="item.send_time_int!=0">
							<!-- <div v-for="(item, index) in conversationList2" :key="item.id"> -->
								<div class="chatRow" :class="conversationIndex == item.id ? 'chatRow_A' : ''"
									@click="changeChat(item.id, item)">

									<el-image class="chatRowX" @click="deleteConversationBtn(item)"
										:class="conversationIndex == item.id ? 'chatRowX_show' : ''"
										:src="require(`@/images/home/contact/close.png`)"></el-image>
									<el-image class="chatAvatar" :src="item.avatar">
										<div slot="error" class="image-slot"> 
											<el-image class="chatAvatar"
											src="https://image.limoapp.net/?data=e7eFdzfaLLG9NQsyBffk7T0timo9ZBsnCdXrN0tFy6EeX3PrD9HU90Y1gcAjEUOXoVCt5mjkAgvmDbwLTyhj6g%3D%3D"
											></el-image>
										</div>
									</el-image>
									<div class="chatRowR">
										<div class="chatRowRTop">
											<div class="chatRowName">
												{{ item.name }}
											</div>
											<div class="chatRowTime">
												{{ timestampToTime(item.send_time_int) }}
											</div>

										</div>
										<div class="chatRowRBottom" v-if="item.type=='f'">
											<div class="chatRowMsg">
												<div :style="{ maxWidth: (212 - 0 * 48) + `px` }" class="ellipsisBox">
													{{ item.content }}
												</div>
											</div>
											<div class="chatRowStatus">
												<!-- 未读消息 -->
												<div class="unread" v-if="item.unread">{{ item.unread>99?'99+':item.unread }}</div>
											</div>

										</div>
										<div class="chatRowRBottom" v-else-if="item.type=='g'">
											<div class="chatRowMsg" v-if="item.exTips&&item.exTips!=0">
												<!-- <div style="color: #FF860E;">[群公告]</div>
												<div>[180条]</div> -->
												<!-- <div style="color: #FF860E;">[群公告]</div>
												<div style="color: #FF860E;">[群喇叭]</div>-->
												<div v-if="item.exTips==1" style="color: #FA3232;margin-right: 3px">{{ $t('chat.yourenaw') }}</div>
												<div v-else-if="item.exTips==2" style="color: #FF860E;margin-right: 3px">{{ $t('chat.qungonggao') }}</div> 
												<div v-else-if="item.exTips==3" style="color: #FF860E;margin-right: 3px">{{ $t('chat.qunlaba') }}</div> 

												<div :style="{ maxWidth: (209 - 1 * 48) + `px` }" class="ellipsisBox">
													{{ item.content }} 
												</div>
											</div>
											<div class="chatRowMsg" v-else>
												<div :style="{ maxWidth: (212 - 0 * 48) + `px` }" class="ellipsisBox">
													{{ item.content }}
												</div>
											</div>
											<!-- 群静音 -->
											<div class="chatRowStatus" v-if="item.is_mute==1">
												
												<el-image class="chatRowStatusMute"
													:src="require(`@/images/home/contact/Mute.png`)"></el-image>
												<!-- 群静音 有新消息 -->
												<div class="redIcon" v-if="item.unread>0"></div>
											</div>
											<!-- 不静音 -->
											<div class="chatRowStatus" v-else>
												<!-- 未读消息 -->
												<div class="unread" v-if="item.unread">{{ item.unread>99?'99+':item.unread }}</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- <div class="chatRow" v-for="i in 10" :key="i" @click="startChat()">
								<el-image class="chatAvatar"
									src="http://401.imyouxun.net:7082/avatar/group/2023/06/rnA5W3B45dBb1lPGbsEIRIPp9LTOBWisQxXceyuP.png"></el-image>
								<div class="chatRowR">
									<div class="chatRowRTop">
										<div class="chatRowName">
											任嘉伦站外女友外女友
										</div>
										<div class="chatRowTime">
											下午 5:00
										</div>

									</div>
									<div class="chatRowRBottom">
										<div class="chatRowMsg">
											<div style="color: #FF860E;">[群公告]</div>
											<div>[180条]</div>
											<div :style="{ maxWidth: (212 - 2 * 48) + `px` }" class="ellipsisBox">
												疯狂的炸鸡翅疯狂的炸鸡翅疯狂的炸鸡翅疯狂的炸鸡</div>
										</div>
										<div class="chatRowStatus">
											群静音
											<el-image class="chatRowStatusMute"
												:src="require(`@/images/home/contact/Mute.png`)"></el-image>
											群静音 有新消息
											<div class="redIcon"></div>
										</div>

									</div>
								</div>
							</div> -->

						</div>

						<!-- 通讯录 -->
						<div class="directoryList scrollbarNo" v-else-if="listType == 2">
							<!-- 群列表 -->
							<div class="directoryListG">
								<div class="directoryListGTitle">
									<el-image class="directoryListGIcon"
										:src="require(`@/images/home/contact/directoryList_g.png`)"></el-image>
									{{ $t('chat.qunliao') }}({{ gLits.length }})
								</div>
								<div class="directoryListC">
									<div class="directoryListRow" @click="openQmsg(item.group_id)"
										:class="directorySelect == item.group_id ? 'directoryListRow_A' : ''"
										v-for="item in gLits" :key="item.id">
										<el-image class="directoryListAvatar" :src="item.logo">
											<div slot="error" class="image-slot"> 
												<el-image class="directoryListAvatar"
												src="https://image.limoapp.net/?data=e7eFdzfaLLG9NQsyBffk7T0timo9ZBsnCdXrN0tFy6EeX3PrD9HU90Y1gcAjEUOXoVCt5mjkAgvmDbwLTyhj6g%3D%3D"
												></el-image>
											</div>
										</el-image>
										<div class="directoryListName">{{ item.groupname }}</div>
									</div>
								</div>
							</div>
							<!-- 好友列表 -->
							<div class="directoryListG">
								<div class="directoryListGTitle">
									<el-image class="directoryListGIcon"
										:src="require(`@/images/home/contact/directoryList_f.png`)"></el-image>
									{{ $t('chat.haoyou') }}({{ directory.length }})
								</div>
								<div class="directoryListC">
									<div class="directoryListRowBox" v-for="(item, index) in directory" :key="index">
										<div class="directoryListRowIndex">{{ item.first }}</div>
										<div class="directoryListRow"
											:class="directorySelect == item2.id ? 'directoryListRow_A' : ''"
											v-for="(item2, index2) in item.list" @click="openPmsg(item2.id)" :key="index2">
											<el-image class="directoryListAvatar" :src="item2.avatar">
												<div slot="error" class="image-slot"> 
													<el-image class="directoryListAvatar"
													src="https://image.limoapp.net/?data=e7eFdzfaLLG9NQsyBffk7T0timo9ZBsnCdXrN0tFy6EeX3PrD9HU90Y1gcAjEUOXoVCt5mjkAgvmDbwLTyhj6g%3D%3D"
													></el-image>
												</div>
											</el-image>
											<div class="directoryListName">{{ item2.name }}</div>
										</div>
									</div>

								</div>
							</div>
						</div>
						

					</div>
					<div class="chatShu"></div>

					<!-- 正常聊天-单聊 -->
					<div class="chatContent" v-if="chatContentBox == 1 && chatType=='f'">
						<!-- 聊天对象名称 -->
						<div class="chatName">{{ selectFmsg.name }}</div>
						<div class="chatHeng"></div>
						<!-- 聊天内容 -->
						<div class="chatMessageBox scrollNew" id="chatMessageBox">
							<!-- 消息加密提醒 -->
							<div class="chatMessageLockTips">
								<el-image class="lockIcon" :src="require(`@/images/home/contact/lock.png`)"></el-image>
								<div class="lockTips">{{ $t('chat.tongguojiami') }}</div>
							</div>
							<!-- 单条信息 -->
							<div class="chatMessageRow" v-for="(item, index) in selectFmsg.list" v-if="item" :key="index">
								<!-- 他人消息 个人 -->
								<div v-if="selectFmsg.data.type == 'f' && item.content.json.from_id != uid">
									<!-- 文本 -->
									<div class="chatMessageOther" v-if="item.content.json.type == 'text'">
										<el-image class="chatMessageAvatar" style="cursor:pointer" @click="seeUserMsg(item)" :src="item.content.json.fromLogo"></el-image>
										<div class="chatMessageOtherR">
											<div class="chatMessageOtherContent" v-html="changeText(item.content.json.content)">
											</div>
										</div>
									</div>
									<!-- 卡券消息 -->
									<div class="chatMessageOther" v-else-if="item.content.json.type == 'coupon_give'">
										<el-image class="chatMessageAvatar" :src="item.content.json.fromLogo"></el-image>
										<div class="chatMessageOtherR">
											<el-image class="chatMessageCoupon"
												:src="require(`@/images/home/contact/couponIcon.png`)"></el-image>
										</div>
									</div>
									<!-- 图片消息 -->
									<div class="chatMessageOther" v-else-if="item.content.json.type == 'picture'">
										<el-image class="chatMessageAvatar" :src="item.content.json.fromLogo"></el-image>
										<div class="chatMessageOtherR" v-if="item.content.json.content.isShowEncryption==0">
											<el-image class="chatMessagePicture" :src="item.content.json.content.url+item.content.json.content.path" :preview-src-list="[item.content.json.content.url+item.content.json.content.path]"></el-image>
										</div>
										<div class="chatMessageOtherR" v-else>
											<div class=" chatMessageOtherContent chatMessageLock" @click="tapUnreadable">
												<el-image class="chatMessageLockIcon" :src="require(`@/images/home/contact/lockB.png`)"></el-image>
												<div class="chatMessageLockText">{{ $t('chat.qianwangapp') }}</div>
											</div>
										</div>
									</div>
									<!-- 表情消息 -->
									<div class="chatMessageOther" v-else-if="item.content.json.type == 'emoticon'">
										<el-image class="chatMessageAvatar" :src="item.content.json.fromLogo"></el-image>
										<div class="chatMessageOtherR" >
											<el-image class="chatMessagePicture" :src="item.content.json.content.url" :preview-src-list="[item.content.json.content.url]"></el-image>
										</div>
									</div>
									<!-- 视频消息 -->
									<div class="chatMessageOther" v-else-if="item.content.json.type == 'video'">
										<el-image class="chatMessageAvatar" :src="item.content.json.fromLogo"></el-image>
										<div class="chatMessageOtherR">
											<div class="chatMessageVideoBox">
												<video class="chatMessageVideo" :src="item.content.json.content.url+item.content.json.content.path">
												</video>
												<el-image class="chatMessageVideoPlay" @click="playVideo(item.content.json.content.url+item.content.json.content.path)"
												:src="require(`@/images/home/contact/playBtn.png`)"></el-image>
												
											</div>
											
										</div>
									</div>
									<!-- 语音消息 -->
									<div class="chatMessageOther" v-else-if="item.content.json.type == 'voice'">
										<el-image class="chatMessageAvatar" :src="item.content.json.fromLogo"></el-image>
										<div class="chatMessageOtherR">
											
											<div class="chatMessageVoice chatMessageOtherContent" @click="playVoice(item.content.json.content,item.content.json.from_id)">
												<el-image class="chatMessageVoiceType" :src="require(`@/images/home/contact/voiceO${item.content.json.content.play?'stop':'play'}.png`)"></el-image>
												<div class="chatMessageVoiceLine" :style="{width:(item.content.json.content.volume.length*4 -2)+'px'}">
													<div class="chatMessageVoiceLinebox1" :style="{width:((item.content.json.content.now?item.content.json.content.now:0)/item.content.json.content.length)*100+'%'}">
														<div class="chatMessageVoiceLinebox " :style="{width:(item.content.json.content.volume.length*4 -2)+'px'}">
															<div v-for="i in item.content.json.content.volume" :style="{height:((i/100)*14+6)+'px'}" class="chatMessageVoiceLineItem LineBlack"  :key="index"></div>
														</div>
													</div>
													
													<div class="chatMessageVoiceLineNow" v-show="item.content.json.content.play" :style="{left:((item.content.json.content.now?item.content.json.content.now:0)/item.content.json.content.length)*100+'%'}"></div>
													<div class="chatMessageVoiceLinebox " :style="{width:(item.content.json.content.volume.length*4 -2)+'px'}">
														<div v-for="i in item.content.json.content.volume" :style="{height:((i/100)*14+6)+'px',}" class="chatMessageVoiceLineItem LineGrey"></div>
													</div>
												</div>
												<div class="chatMessageVoiceSS">{{ item.content.json.content.length + 's'}}</div>
											</div>
										</div>
									</div>
									<!-- 不可查看消息 -->
									<div class="chatMessageOther" v-else>
										<el-image class="chatMessageAvatar" :src="item.content.json.fromLogo"></el-image>
										<div class="chatMessageOtherR">
											
											<div class=" chatMessageOtherContent chatMessageLock" @click="tapUnreadable">
												<el-image class="chatMessageLockIcon" :src="require(`@/images/home/contact/lockB.png`)"></el-image>
												<div class="chatMessageLockText">{{ $t('chat.qianwangapp') }}</div>
											</div>
										</div>
									</div>
								</div>

								<!-- 自己消息 个人 -->
								<div v-if="selectFmsg.data.type == 'f' && item.content.json.from_id == uid">
									<div class="chatMessageSelf" v-if="item.content.json.type == 'text'">
										<el-image class="chatMessageAvatar" :src="userInfo.avatar"></el-image>
										<div class="chatMessageSelfL">
											<div class="chatMessageSelfContent" v-html="changeText(item.content.json.content)">
											</div>
										</div>
									</div>
									<div class="chatMessageSelf" v-else-if="item.content.json.type == 'coupon_give'">
										<el-image class="chatMessageAvatar" :src="userInfo.avatar"></el-image>
										<div class="chatMessageSelfL">
											<el-image class="chatMessageCoupon"
												:src="require(`@/images/home/contact/couponIcon.png`)"></el-image>
										</div>
									</div>
									<div class="chatMessageSelf" v-else-if="item.content.json.type == 'picture'">
										<el-image class="chatMessageAvatar" :src="userInfo.avatar"></el-image>
										<div class="chatMessageSelfL" v-if="item.content.json.content.isShowEncryption==0">
											<el-image class="chatMessagePicture" :src="item.content.json.content.url+item.content.json.content.path" :preview-src-list="[item.content.json.content.url+item.content.json.content.path]"></el-image>
										</div>
										<div class="chatMessageSelfL" v-else>
											<div class=" chatMessageSelfContent chatMessageLock" @click="tapUnreadable">
												<el-image class="chatMessageLockIcon" :src="require(`@/images/home/contact/lockW.png`)"></el-image>
												<div class="chatMessageLockText">{{ $t('chat.qianwangapp') }}</div>
											</div>
										</div>
									</div>
									<div class="chatMessageSelf" v-else-if="item.content.json.type == 'emoticon'">
										<el-image class="chatMessageAvatar" :src="userInfo.avatar"></el-image>
										<div class="chatMessageSelfL" >
											<el-image class="chatMessagePicture" :src="item.content.json.content.url" :preview-src-list="[item.content.json.content.url]"></el-image>
										</div>
									</div>
									<div class="chatMessageSelf" v-else-if="item.content.json.type == 'video'">
										<el-image class="chatMessageAvatar" :src="userInfo.avatar"></el-image>
										<div class="chatMessageSelfL">
											<div class="chatMessageVideoBox">
												<video class="chatMessageVideo" :src="item.content.json.content.url+item.content.json.content.path">
												</video>
												<el-image class="chatMessageVideoPlay" @click="playVideo(item.content.json.content.url+item.content.json.content.path)"
												:src="require(`@/images/home/contact/playBtn.png`)"></el-image>
											</div>
										</div>
									</div>
									<div class="chatMessageSelf" v-else-if="item.content.json.type == 'voice'">
										<el-image class="chatMessageAvatar" :src="userInfo.avatar"></el-image>
										<div class="chatMessageSelfL">
											<div class="chatMessageVoice chatMessageSelfContent" @click="playVoice(item.content.json.content,item.content.json.to_id)">
												<el-image class="chatMessageVoiceType" :src="require(`@/images/home/contact/voiceS${item.content.json.content.play?'stop':'play'}.png`)"></el-image>
												<div class="chatMessageVoiceLine" :style="{width:(item.content.json.content.volume.length*4 -2)+'px'}">
													<div class="chatMessageVoiceLinebox1" :style="{width:((item.content.json.content.now?item.content.json.content.now:0)/item.content.json.content.length)*100+'%'}">
														<div class="chatMessageVoiceLinebox chatMessageVoiceLineboxB" :style="{width:(item.content.json.content.volume.length*4 -2)+'px'}">
															<div v-for="i in item.content.json.content.volume" :style="{height:((i/100)*14+6)+'px'}" class="chatMessageVoiceLineItem LineWhite"></div>
														</div>
													</div>
													<div class="chatMessageVoiceLineNow chatMessageVoiceLineNowW" v-show="item.content.json.content.play" :style="{left:((item.content.json.content.now?item.content.json.content.now:0)/item.content.json.content.length)*100+'%'}"></div>
													<div class="chatMessageVoiceLinebox chatMessageVoiceLineboxB" :style="{width:(item.content.json.content.volume.length*4 -2)+'px'}">
														<div v-for="i in item.content.json.content.volume" :style="{height:((i/100)*14+6)+'px',}" class="chatMessageVoiceLineItem LineGrey2"></div>
													</div>
												</div>
												<div class="chatMessageVoiceSS">{{ item.content.json.content.length + 's'}}</div>
											</div>
										</div>
									</div>

									<div class="chatMessageSelf" v-else>
										<el-image class="chatMessageAvatar" :src="userInfo.avatar"></el-image>
										<div class="chatMessageSelfL">
											<div class=" chatMessageSelfContent chatMessageLock" @click="tapUnreadable">
												<el-image class="chatMessageLockIcon" :src="require(`@/images/home/contact/lockW.png`)"></el-image>
												<div class="chatMessageLockText">{{ $t('chat.qianwangapp') }}</div>
											</div>
										</div>
									</div>
								</div>




							</div>
							<!-- 单条信息 -->
							
						</div>
						<!-- 新消息 -->
						<div class="exTipsBtn newMsgBtn" v-if="hasNew&&((chatType=='f'&&selectFmsg.list.length>6)||(chatType=='g'&&selectGroupMsg.list.length>5))">
							<div class="exTipsBtnAt"  @click="hasNewBtn()">
								{{ $t('chat.youxinxiaoxi') }}
							</div>
						</div>
						<!-- 聊天回复盒子 -->
						<div class="chatReplyBox">
							<!-- 聊天表情包 上传文件 -->
							<div class="chatReplyTools">
								<div class="chatReplyToolsItem">
									<el-image class="chatReplyToolsIcon" @click="clickEmoji()"
									:src="require(`@/images/home/contact/chatTool1.png`)"></el-image>
								</div>
								<div class="chatReplyToolsItem chatReplyToolsItem2">
									<el-upload  :headers="headers" :action="uploadImgUrl" :accept="'image/*,video/*'" :show-file-list="false"
										:on-success="uploadEditorSuccess" :data="uploadData" :before-upload="beforeUpload">
										<el-image class="chatReplyToolsIcon" :src="require(`@/images/home/contact/chatTool2.png`)"></el-image>
									</el-upload>
								</div>
								
							</div>
							<!-- 聊天输入 -->
							<textarea maxlength="500" class="chatTextarea scrollNew " v-model="chatText" ref="ipt"
								@keyup.ctrl.enter.exact="chatToUser('ctrlenter', $event)"
								@keyup.enter.exact="chatToUser('enter', $event)" @keydown.enter.prevent></textarea>
							<div class="chatSendRow">
								<div class="chatSendBtn" :class="chatText?'':'UnchatText'" @click="chatToUser('enter')">{{ $t('chat.fasong') }}</div>
								<div class="chatSendTips">{{ $t('chat.huanhang') }}</div>
							</div>
						</div>
					</div>
					<!-- 正常聊天-群聊 -->
					<div class="chatContent" v-if="chatContentBox == 1 && chatType=='g'">
						<!-- 聊天对象名称 -->
						<div class="chatName">{{ selectGroupMsg.name }} <span v-show="selectGroupInfo.total">{{ '('+selectGroupInfo.total+')' }}</span></div>
						<div class="chatHeng"></div>
						<!-- 聊天内容 -->
						<div class="chatMessageBox scrollNew" id="chatMessageBox">
							<!-- 消息加密提醒 -->
							<div class="chatMessageLockTips">
								<el-image class="lockIcon" :src="require(`@/images/home/contact/lock.png`)"></el-image>
								<div class="lockTips">{{ $t('chat.tongguojiami') }}</div>
							</div>
							<!-- 单条信息 -->
							<div class="chatMessageRow" v-for="(item, index) in selectGroupMsg.list" v-if="item">

								<!-- 他人消息 群 -->
								<div v-if="item.content.json.from_id != uid && item.content.json.type != 'coupon_get_group'&& item.content.json.type != 'group_tips'">
									<!-- 文本 -->
									<div class="chatMessageOther" v-if="item.content.json.type == 'text'">
										<el-image class="chatMessageAvatar" :src="item.content.json.fromLogo" ></el-image>
										<div class="chatMessageOtherR">
											<div class="chatMessageOtherName">
												{{ item.content.json.fromName }}
											</div>
											<div class="chatMessageOtherContent" v-html="changeText(item.content.json.content)">

											</div>
										</div>
									</div>
									<!-- 卡券消息 -->
									<div class="chatMessageOther" v-else-if="item.content.json.type == 'coupon_give_group'">
										<el-image class="chatMessageAvatar" :src="item.content.json.fromLogo" ></el-image>
										<div class="chatMessageOtherR">
											<div class="chatMessageOtherName">
												{{ item.content.json.fromName }}
											</div>
											<el-image class="chatMessageCoupon"
												:src="require(`@/images/home/contact/couponIcon.png`)"></el-image>
										</div>
									</div>
									<!-- 图片消息 -->
									<div class="chatMessageOther" v-else-if="item.content.json.type == 'picture'">
										<el-image class="chatMessageAvatar" :src="item.content.json.fromLogo" ></el-image>
										<div class="chatMessageOtherR" v-if="item.content.json.content.isShowEncryption==0">
											<div class="chatMessageOtherName">
												{{ item.content.json.fromName }}
											</div>
											<el-image class="chatMessagePicture" :src="item.content.json.content.url+item.content.json.content.path" :preview-src-list="[item.content.json.content.url+item.content.json.content.path]"></el-image>
										</div>
										<div class="chatMessageOtherR" v-else>
											<div class="chatMessageOtherName">
												{{ item.content.json.fromName }}
											</div>
											<div class="chatMessageOtherContent chatMessageLock" @click="tapUnreadable">
												<el-image class="chatMessageLockIcon" :src="require(`@/images/home/contact/lockB.png`)"></el-image>
												<div class="chatMessageLockText">{{ $t('chat.qianwangapp') }}</div>
											</div>
										</div>
									</div>
									<!-- 表情消息 -->
									<div class="chatMessageOther" v-else-if="item.content.json.type == 'emoticon'">
										<el-image class="chatMessageAvatar" :src="item.content.json.fromLogo" ></el-image>
										<div class="chatMessageOtherR" >
											<div class="chatMessageOtherName">
												{{ item.content.json.fromName }}
											</div>
											<el-image class="chatMessagePicture" :src="item.content.json.content.url" :preview-src-list="[item.content.json.content.url]"></el-image>
										</div>
									</div>
									<!-- 视频消息 -->
									<div class="chatMessageOther" v-else-if="item.content.json.type == 'video'">
										<el-image class="chatMessageAvatar" :src="item.content.json.fromLogo" ></el-image>
										<div class="chatMessageOtherR">
											<div class="chatMessageOtherName">
												{{ item.content.json.fromName }}
											</div>
											<div class="chatMessageVideoBox">
												<video class="chatMessageVideo" :src="item.content.json.content.url+item.content.json.content.path">
												</video>
												<el-image class="chatMessageVideoPlay" @click="playVideo(item.content.json.content.url+item.content.json.content.path)"
												:src="require(`@/images/home/contact/playBtn.png`)"></el-image>
												
											</div>
										</div>
									</div>
									<!-- 语音消息 -->
									<div class="chatMessageOther" v-else-if="item.content.json.type == 'voice'">
										<el-image class="chatMessageAvatar" :src="item.content.json.fromLogo" ></el-image>
										<div class="chatMessageOtherR">
											<div class="chatMessageOtherName">
												{{ item.content.json.fromName }}
											</div>
											<div class="chatMessageVoice chatMessageOtherContent" @click="playVoice(item.content.json.content,item.content.json.to_gid)">
												<el-image class="chatMessageVoiceType" :src="require(`@/images/home/contact/voiceO${item.content.json.content.play?'stop':'play'}.png`)"></el-image>
												<div class="chatMessageVoiceLine" :style="{width:(item.content.json.content.volume.length*4 -2)+'px'}">
													<div class="chatMessageVoiceLinebox1" :style="{width:((item.content.json.content.now?item.content.json.content.now:0)/item.content.json.content.length)*100+'%'}">
														<div class="chatMessageVoiceLinebox " :style="{width:(item.content.json.content.volume.length*4 -2)+'px'}">
															<div v-for="i in item.content.json.content.volume" :style="{height:((i/100)*14+6)+'px'}" class="chatMessageVoiceLineItem LineBlack"></div>
														</div>
													</div>
													
													<div class="chatMessageVoiceLineNow" v-show="item.content.json.content.play" :style="{left:((item.content.json.content.now?item.content.json.content.now:0)/item.content.json.content.length)*100+'%'}"></div>
													<div class="chatMessageVoiceLinebox " :style="{width:(item.content.json.content.volume.length*4 -2)+'px'}">
														<div v-for="i in item.content.json.content.volume" :style="{height:((i/100)*14+6)+'px',}" class="chatMessageVoiceLineItem LineGrey"></div>
													</div>
												</div>
												<div class="chatMessageVoiceSS">{{ item.content.json.content.length + 's'}}</div>
											</div>
											
										</div>
									</div>
									<!-- @消息 -->
									<div class="chatMessageOther " :class="hasAt(item.content.json.content)?'chatMessageSelfAtmsg':''" v-else-if="item.content.json.type == 'at_rich_text'">
										<el-image class="chatMessageAvatar" :src="item.content.json.fromLogo" ></el-image>
										<div class="chatMessageOtherR">
											<div class="chatMessageOtherName">
												{{ item.content.json.fromName }}
											</div>
											<div class="chatMessageOtherContent" v-html="changeText(item.content.json.content.content)">

											</div>
										</div>
									</div>
									<!-- 群公告 -->
									<div class="chatMessageOther " v-else-if="item.content.json.type == 'group_notice'">
										<el-image class="chatMessageAvatar" :src="item.content.json.fromLogo" ></el-image>
										<div class="chatMessageOtherR">
											<div class="chatMessageOtherName">
												{{ item.content.json.fromName }}
											</div>
											<div class="chatMessageOtherContent chatMessageNotice">
												<div class="chatMessageNoticeTop chatMessageNoticeTopL">
													<el-image class="chatMessageLaba" :src="require(`@/images/home/contact/laba.png`)" ></el-image>
													{{ $t('chat.qungonggao') }}
												</div>
												<div class="chatMessageNoticeBot">
													{{ item.content.json.content.group_notice }}
												</div>
											</div>
										</div>
									</div>
									<!-- 群喇叭 -->
									<div class="chatMessageOther" v-else-if="item.content.json.type == 'group_broadcast'">
										<el-image class="chatMessageAvatar" :src="item.content.json.fromLogo" ></el-image>
										<div class="chatMessageOtherR">
											<div class="chatMessageOtherName">
												{{ item.content.json.fromName }}
											</div>
											<div class="chatMessageOtherContent" >
												<div class="chatMessageLabaTop">
													<el-image class="chatMessageLaba2" :src="require(`@/images/home/contact/laba.png`)" ></el-image>
													{{ $t('chat.laiziqunlab') }}
												</div>
												<div>
													{{ item.content.json.content.content }}
												</div>
											</div>
										</div>
									</div>
									<!-- 不可查看消息 -->
									<div class="chatMessageOther" v-else>
										<el-image class="chatMessageAvatar" :src="item.content.json.fromLogo" ></el-image>
										<div class="chatMessageOtherR">
											<div class="chatMessageOtherName">
												{{ item.content.json.fromName }}
											</div>
											<div class=" chatMessageOtherContent chatMessageLock" @click="tapUnreadable">
												<el-image class="chatMessageLockIcon" :src="require(`@/images/home/contact/lockB.png`)"></el-image>
												<div class="chatMessageLockText">{{ $t('chat.qianwangapp') }}</div>
											</div>
										</div>
									</div>


								</div>



								<!-- 自己消息 群 -->
								<!-- 右键新菜单栏 -->
								<!-- <div v-if="item.content.json.from_id == uid && item.content.json.type != 'coupon_get_group'&& item.content.json.type != 'group_tips'" v-on:click.right.prevent="rightClick($event,item)"> -->
								<div v-if="item.content.json.from_id == uid && item.content.json.type != 'coupon_get_group'&& item.content.json.type != 'group_tips'" >
									<div class="chatMessageSelf" v-if="item.content.json.type == 'text'">
										<el-image class="chatMessageAvatar" :src="userInfo.avatar" ></el-image>
										<div class="chatMessageSelfL chatMessageSelfL_g">
											<div class="chatMessageSelfName">
												{{ userInfo.nickname }}
											</div>
											<div class="chatMessageSelfContent" v-html="changeText(item.content.json.content)"></div>
										</div>
									</div>
									<!-- 卡券消息 -->
									<div class="chatMessageSelf" v-else-if="item.content.json.type == 'coupon_give_group'">
										<el-image class="chatMessageAvatar" :src="userInfo.avatar" ></el-image>
										<div class="chatMessageSelfL chatMessageSelfL_g">
											<div class="chatMessageSelfName">
												{{ userInfo.nickname }}
											</div>
											<el-image class="chatMessageCoupon" :src="require(`@/images/home/contact/couponIcon.png`)"></el-image>
										</div>
									</div>
									<!-- 图片消息 -->
									<div class="chatMessageSelf" v-else-if="item.content.json.type == 'picture'">
										<el-image class="chatMessageAvatar" :src="userInfo.avatar" ></el-image>
										<div class="chatMessageSelfL chatMessageSelfL_g" v-if="item.content.json.content.isShowEncryption==0">
											<div class="chatMessageSelfName">
												{{ userInfo.nickname }}
											</div>
											<el-image class="chatMessagePicture" :src="item.content.json.content.url+item.content.json.content.path" :preview-src-list="[item.content.json.content.url+item.content.json.content.path]"></el-image>
										</div>
										<div class="chatMessageSelfL chatMessageSelfL_g" v-else>
											<div class="chatMessageSelfName">
												{{ userInfo.nickname }}
											</div>
											<div class=" chatMessageSelfContent chatMessageLock" @click="tapUnreadable">
												<el-image class="chatMessageLockIcon" :src="require(`@/images/home/contact/lockW.png`)"></el-image>
												<div class="chatMessageLockText">{{ $t('chat.qianwangapp') }}</div>
											</div>
										</div>
									</div>
									<!-- 表情消息 -->
									<div class="chatMessageSelf" v-else-if="item.content.json.type == 'emoticon'">
										<el-image class="chatMessageAvatar" :src="userInfo.avatar" ></el-image>
										<div class="chatMessageSelfL chatMessageSelfL_g">
											<div class="chatMessageSelfName">
												{{ userInfo.nickname }}
											</div>
											<el-image class="chatMessagePicture" :src="item.content.json.content.url" :preview-src-list="[item.content.json.content.url]"></el-image>
										</div>
									</div>
									<!-- 视频消息 -->
									<div class="chatMessageSelf" v-else-if="item.content.json.type == 'video'">
										<el-image class="chatMessageAvatar" :src="userInfo.avatar" ></el-image>
										<div class="chatMessageSelfL  chatMessageSelfL_g">
											<div class="chatMessageSelfName">
												{{ userInfo.nickname }}
											</div>
											<div class="chatMessageVideoBox">
												<video class="chatMessageVideo" :src="item.content.json.content.url+item.content.json.content.path">
												</video>
												<el-image class="chatMessageVideoPlay" @click="playVideo(item.content.json.content.url+item.content.json.content.path)"
												:src="require(`@/images/home/contact/playBtn.png`)"></el-image>
											</div>
										</div>
									</div>
									<!-- 语音消息 -->
									<div class="chatMessageSelf" v-else-if="item.content.json.type == 'voice'">
										<el-image class="chatMessageAvatar" :src="userInfo.avatar" ></el-image>
										<div class="chatMessageSelfL  chatMessageSelfL_g">
											<div class="chatMessageSelfName">
												{{ userInfo.nickname }}
											</div>
											<div class="chatMessageVoice chatMessageSelfContent" @click="playVoice(item.content.json.content,item.content.json.to_gid)">
												<el-image class="chatMessageVoiceType" :src="require(`@/images/home/contact/voiceS${item.content.json.content.play?'stop':'play'}.png`)"></el-image>
												<div class="chatMessageVoiceLine" :style="{width:(item.content.json.content.volume.length*4 -2)+'px'}">
													<div class="chatMessageVoiceLinebox1" :style="{width:((item.content.json.content.now?item.content.json.content.now:0)/item.content.json.content.length)*100+'%'}">
														<div class="chatMessageVoiceLinebox chatMessageVoiceLineboxB" :style="{width:(item.content.json.content.volume.length*4 -2)+'px'}">
															<div v-for="i in item.content.json.content.volume" :style="{height:((i/100)*14+6)+'px'}" class="chatMessageVoiceLineItem LineWhite"></div>
														</div>
													</div>
													<div class="chatMessageVoiceLineNow chatMessageVoiceLineNowW" v-show="item.content.json.content.play" :style="{left:((item.content.json.content.now?item.content.json.content.now:0)/item.content.json.content.length)*100+'%'}"></div>
													<div class="chatMessageVoiceLinebox chatMessageVoiceLineboxB" :style="{width:(item.content.json.content.volume.length*4 -2)+'px'}">
														<div v-for="i in item.content.json.content.volume" :style="{height:((i/100)*14+6)+'px',}" class="chatMessageVoiceLineItem LineGrey2"></div>
													</div>
												</div>
												<div class="chatMessageVoiceSS">{{ item.content.json.content.length + 's'}}</div>
											</div>
										</div>
									</div>
									<!-- @消息 -->
									<div class="chatMessageSelf" v-else-if="item.content.json.type == 'at_rich_text'">
										<el-image class="chatMessageAvatar" :src="userInfo.avatar" ></el-image>
										<div class="chatMessageSelfL chatMessageSelfL_g">
											<div class="chatMessageSelfName">
												{{ userInfo.nickname }}
											</div>
											<div class="chatMessageSelfContent" v-html="changeText(item.content.json.content.content)"></div>
										</div>
									</div>
									<!-- 群公告 -->
									<div class="chatMessageSelf " v-else-if="item.content.json.type == 'group_notice'">
										<el-image class="chatMessageAvatar" :src="item.content.json.fromLogo" ></el-image>
										<div class="chatMessageSelfL chatMessageSelfL_g">
											<div class="chatMessageSelfName">
												{{ item.content.json.fromName }}
											</div>
											<div class="chatMessageSelfContent chatMessageNotice">
												<div class="chatMessageNoticeTop chatMessageNoticeTopR">
													<el-image class="chatMessageLaba" :src="require(`@/images/home/contact/laba.png`)" ></el-image>
													{{ $t('chat.qungonggao') }}
												</div>
												<div class="chatMessageNoticeBot">
													{{ item.content.json.content.group_notice }}
												</div>
											</div>
										</div>
									</div>
									<!-- 群喇叭 -->
									<div class="chatMessageSelf" v-else-if="item.content.json.type == 'group_broadcast'">
										<el-image class="chatMessageAvatar" :src="userInfo.avatar" ></el-image>
										<div class="chatMessageSelfL chatMessageSelfL_g">
											<div class="chatMessageSelfName">
												{{ userInfo.nickname }}
											</div>
											<div class="chatMessageSelfContent" >
												<div class="chatMessageLabaTop">
													<el-image class="chatMessageLaba2" :src="require(`@/images/home/contact/labaW.png`)" ></el-image>
													{{ $t('chat.laiziqunlab') }}
												</div>
												<div>
													{{ item.content.json.content.content }}
												</div>
											</div>
										</div>
									</div>
									<!-- 不可查看消息 -->
									<div class="chatMessageSelf" v-else >
										<el-image class="chatMessageAvatar" :src="userInfo.avatar" ></el-image>
										<div class="chatMessageSelfL  chatMessageSelfL_g">
											<div class="chatMessageSelfName">
												{{ userInfo.nickname }}
											</div>
											<div class=" chatMessageSelfContent chatMessageLock" @click="tapUnreadable">
												<el-image class="chatMessageLockIcon" :src="require(`@/images/home/contact/lockW.png`)"></el-image>
												<div class="chatMessageLockText">{{ $t('chat.qianwangapp') }}</div>
											</div>
										</div>
									</div>
									
									
								</div>
								<!-- 系统式消息 -->
								<!-- 卡券被接收 消息 -->
								<div class="chatMessageSystem" v-if="item.content.json.type == 'coupon_get_group'">
									<span style="color: #3486FF;">{{ item.content.json.content.receive_nickname }}</span>{{ $t('chat.lingqule') }}<span style="color: #3486FF;">{{ item.content.json.content.give_nickname }}</span>{{ $t('chat.dekaquan') }}
								</div>
								<!-- 群提示 -->
								<div class="chatMessageSystem" v-else-if="item.content.json.type == 'group_tips'">
									{{ item.content.json.content.friend_msg }}
									<!-- <span style="color: #3486FF;">{{ JSON.parse(item.content.json.content).receive_nickname }}</span>领取了<span style="color: #3486FF;">{{ JSON.parse(item.content.json.content).give_nickname }}</span>的卡券 -->
								</div>
							</div>
							
						</div>
						<!-- 单条信息end -->
						<!-- 特殊消息 -->
						<div class="exTipsBtn" v-if="selectGroupMsg.chatBoxExTips!=0">
							<div class="exTipsBtnAt" v-if="selectGroupMsg.chatBoxExTips==1" @click="exTipsBtnAt()">
								{{ $t('chat.youanide') }}
							</div>
						</div>
						<!-- 新消息 -->
						<div class="exTipsBtn newMsgBtn" v-if="hasNew&&((chatType=='f'&&selectFmsg.list.length>6)||(chatType=='g'&&selectGroupMsg.list.length>5))&&!isGoBottom">
							<div class="exTipsBtnAt"  @click="hasNewBtn()">
								{{ $t('chat.youxinxiaoxi') }}
							</div>
						</div>
						<!-- 群喇叭置顶 -->
						<div class="groupLaba" v-if="showQlaba" @click="showQlaba=false">
							<el-image class="groupLabaIcon" :src="showQlabaData.fromLogo"></el-image>
							<div class="groupLabaR">
								<div class="groupLabaFromName">{{ showQlabaData.fromName }}</div>
								<div class="groupLabaContent">{{ showQlabaData.content.content }}</div>
							</div>

						</div>
						<!-- 聊天回复盒子 -->
						<div class="chatReplyBox">
							<!-- 聊天表情包 上传文件 -->
							<div class="chatReplyTools">
								<div class="chatReplyToolsItem">
									<el-image class="chatReplyToolsIcon" @click="clickEmoji()" :src="require(`@/images/home/contact/chatTool1.png`)"></el-image>
								</div>
								<div class="chatReplyToolsItem chatReplyToolsItem2" >
									<el-upload :headers="headers" :action="uploadImgUrl" :accept="'image/*,video/*'" :show-file-list="false"
										:on-success="uploadEditorSuccess" :data="uploadData" :before-upload="beforeUpload">
										<el-image class="chatReplyToolsIcon" :src="require(`@/images/home/contact/chatTool2.png`)"></el-image>
									</el-upload>
								</div>
							</div>
							<!-- 聊天输入 -->
							<textarea maxlength="500" class="chatTextarea scrollNew " v-model="chatText"  ref="ipt"
								@keyup.ctrl.enter.exact="chatToUser('ctrlenter', $event)"
								@keyup.enter.exact="chatToUser('enter', $event)" @keydown.enter.prevent></textarea>
							<div class="chatSendRow">
								<div class="chatSendBtn" :class="chatText?'':'UnchatText'" @click="chatToUser('enter')">{{ $t('chat.fasong') }}</div>
								<div class="chatSendTips">{{ $t('chat.huanhang') }}</div>
							</div>
						</div>
						
					</div>
					

					<!-- 通讯录点击-开始群聊 -->
					<div class="chatContent" v-if="chatContentBox == 3">
						<div class="chatName">{{ selectGroupInfo.groupname }}<span v-show="selectGroupInfo.total">{{ '('+selectGroupInfo.total+')' }}</span></div>
						<div class="chatHeng"></div>
						<div class="starQchat">
							<div class="QnumberBox scrollNew">
								<div class="Qnumber" v-for="item in selectGroupInfo.list" :key="item.uid">
									<el-image class="QnumberAvatar"
										:src="item.avatar">
										<div slot="error" class="image-slot"> 
											<el-image class="QnumberAvatar"
											src="https://image.limoapp.net/?data=e7eFdzfaLLG9NQsyBffk7T0timo9ZBsnCdXrN0tFy6EeX3PrD9HU90Y1gcAjEUOXoVCt5mjkAgvmDbwLTyhj6g%3D%3D"
											></el-image>
										</div>
									</el-image>
									<div class="QnumberName">{{ item.remark_name ?item.remark_name :item.group_nickname ?item.group_nickname:item.nickname }}</div>
								</div>
								<div class="QnumberK" v-if="selectGroupInfo.list&&(selectGroupInfo.list.length % 7 != 0)" v-for="i in (7 - selectGroupInfo.list.length % 7)" :key="i"></div>
								<div class="QnumberMore" v-if="selectGroupInfo.hasNext">
									<div class="QnumberMoreBtn" @click="getGroupMenberBtnMore">
										{{ $t('chat.jiazaigengduo') }}
									</div>
									
								</div>
							</div>
							<div class="starQchatBtn" @click="startChat(selectGroupInfo.group_id,'g')">
								{{ $t('chat.fasongxiaoxi') }}
							</div>
						</div>
					</div>
					<!-- 通讯录点击-开始单聊 -->
					<div class="chatContent" v-if="chatContentBox == 2">
						<div class="starPchat">
							<el-image class="PnumberAvatar" :src="selectFinfo.avatar"></el-image>
							<div class="PnumberName">{{ selectFinfo.nickname }}</div>
							<div class="PnumberID">
								ID: {{ selectFinfo.unique_id }}
							</div>
							<div class="PnumberLV">
								<div class="PnumberLVNum">
									LV.{{ selectFinfo.level }}
								</div>
								<div class="PnumberLVIconBox" v-if="selectFinfo.level">
									<el-image v-for="(item1, xingindex) in (selectFinfo.level % 4)" :key="xingindex"
										class="PnumberLVIcon" :src="require(`@/images/home/contact/lv1.png`)"></el-image>
									<el-image v-if="selectFinfo.level + 1 >= 4"
										v-for="(item2, yueindex) in (parseInt(selectFinfo.level / 4) % 4)"
										:key="yueindex + 10001" class="PnumberLVIcon"
										:src="require(`@/images/home/contact/lv4.png`)"></el-image>
									<el-image v-if="selectFinfo.level + 1 >= 16"
										v-for="(item3, riindex) in (parseInt(selectFinfo.level / 16) % 4)"
										:key="riindex + 20001" class="PnumberLVIcon"
										:src="require(`@/images/home/contact/lv16.png`)"></el-image>
									<el-image v-if="selectFinfo.level + 1 >= 64"
										v-for="(item4, hgindex) in (parseInt(selectFinfo.level / 64) % 4)"
										:key="hgindex + 30001" class="PnumberLVIcon"
										:src="require(`@/images/home/contact/lv64.png`)"></el-image>
								</div>
							</div>
							<div class="PnumberSign">
								{{ selectFinfo.sign }}
							</div>
							<div class="starPchatBtn" @click="startChat(selectFinfo.uid,'f')">
								{{ $t('chat.fasongxiaoxi') }}
							</div>
						</div>
					</div>
					<!-- 无聊天 -->
					<div class="chatContent" v-if="chatContentBox == 0">
						<div class="noChat">
							<el-image class="noChatIcon" :src="require(`@/images/home/contact/img_jiazai.png`)"></el-image>

						</div>
					</div>
					<!-- 加载中-->
					<div class="chatContent" v-if="chatContentBox == 4">
						<div class="noChat">
							<van-loading type="spinner" size="60px" />
						</div>
					</div>
					<!-- 个人信息弹窗 -->
					<div class="userMsgBox" @click="closeUserMsg" v-show="showUserMsg">
						<div class="userMsg" @click.stop="infoTapTips">
							<div class="userMsgRow1">
								<el-image class="userAvatar" :src="userInfo.avatar" @click="infoTapTips()"></el-image>
								<div class="userMsgRow1R" >
									<div class="userName">
										{{ userInfo.nickname }}
									</div>
									<div class="userID">ID:{{ userInfo.unique_id }}</div>
								</div>
							</div>
							<!-- userLV -->
							<div class="userMsgRow2">
								<div class="userMsgRow2LV">
									LV.{{ userInfo.level }}
								</div>
								<div v-if="userInfo.level" class="userMsgRow2LVIconBox">
									<el-image v-for="(item1, xingindex) in (userInfo.level % 4)" :key="xingindex"
										class="userMsgRow2LVIcon"
										:src="require(`@/images/home/contact/lv1.png`)"></el-image>
									<el-image v-if="userInfo.level + 1 >= 4"
										v-for="(item2, yueindex) in (parseInt(userInfo.level / 4) % 4)"
										:key="yueindex + 10001" class="userMsgRow2LVIcon"
										:src="require(`@/images/home/contact/lv4.png`)"></el-image>
									<el-image v-if="userInfo.level + 1 >= 16"
										v-for="(item3, riindex) in (parseInt(userInfo.level / 16) % 4)"
										:key="riindex + 20001" class="userMsgRow2LVIcon"
										:src="require(`@/images/home/contact/lv16.png`)"></el-image>
									<el-image v-if="userInfo.level + 1 >= 64"
										v-for="(item4, hgindex) in (parseInt(userInfo.level / 64) % 4)"
										:key="hgindex + 30001" class="userMsgRow2LVIcon"
										:src="require(`@/images/home/contact/lv64.png`)"></el-image>
								</div>
							</div>
							<div class="userMsgRow3">
								{{ $t('chat.gexingqianming') }}：{{ userInfo.sign }}
							</div>
							<div class="logoutBtn" @click.stop="logoutBoxBtn">
								{{ $t('chat.logout') }}
							</div>
						</div>
					</div>

					<!-- 删除按钮 -->
					<div class="deleteBtnCover" @click="showDeleteBtn=false" v-if="showDeleteBtn">
						<div class="deleteBtn" @click.stop="deleteBtn" :style="{marginLeft:showDeleteBtnleft+'px',marginTop:showDeleteBtnTop+'px',}">
							<el-image class="deleteBtnIcon"  :src="require(`@/images/home/contact/shanchu.png`)"></el-image>
							{{ $t('chat.shanchu') }}
						</div>

					</div>

					<div class="emojiBoxCover" @click="closeEmoji" v-show="emojiShow">
						<div class="emojiBox" @click.stop>
							<div class="emoItem" v-for="(item,index) in emojiLits" :key="item.id" @click="tapEmoji(index)">
								<el-image class="emoItemIcon" :src="require(`@/images/home/contact/emoji/face_routine_round_${item.id}.png`)"></el-image>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 未登录 -->
			<div class="contentBox" :style="{transform:' scale('+scale+')'}" v-else>
				<div class="unLoginBox">
					<div class="unLogintitle">
						{{ $t('chat.qingliodenglu') }}
					</div>
					<div class="unLoginCodeBox"
						:style="{ backgroundImage: 'url(' + require('@/images/home/contact/img_shimida_saomiaoerweima@2x.png') + ')' }">
						<el-image v-if="qrcode_url" class="unLoginCode unLoginCode1" :style="{ opacity: loginTpye == 0 ? '1' : '.1' }"
							:src="qrcode_url">
						</el-image>
						<div v-else>
							<van-loading />
						</div>
						<div class="qrcodeErr" v-if="loginTpye == 100010" @click="getQrCode()">
							<el-image class="loginOKImg" :src="require('@/images/home/contact/loginOK2.png')"></el-image>
							{{ $t('chat.erweimashixiao') }}
						</div>
						<div class="loginOK" v-else-if="loginTpye == 1 || loginTpye == 2">
							<el-image class="loginOKImg" :src="require('@/images/home/contact/loginOK.png')"></el-image>
							{{ loginTpye == 1 ? $t('chat.saomacg') : loginTpye == 2 ? $t('chat.denglucg') : '' }}
						</div>
					</div>
					<div class="unLoginSubTitle">
						{{ $t('chat.appsaoma') }}
					</div>
					<div class="unLoginTips">
						<div class="unLoginRow" v-for="i in 4" :key="i">{{ $t(`chat.guize${i}`) }}</div>
			
					</div>
				</div>
			</div>


		</div>
		<!-- 视频播放盒子 -->
		<div class="videoBox" v-show="playVideoBox">
			<div class="videoCover" ></div>
			<div class="videoView" @click="closeVideoBox">
				<video @click.stop class="videoItem" ref="videoRef" :src="playVideoUrl" @timeupdate="handleTimeUpdate" autoplay controls></video>
			</div>
		</div> 
		<div class="audioBox" >
			<audio ref="audio">
				<source :src="voiceUrl" type="">
			</audio>
		</div> 
		<div class="logoutBoxCover" v-if="logoutBoxShow">
			<div class="logoutBox">
				<div class="logoutBoxTitle">{{ $t('chat.tuichubiaoti') }}</div>
				<div class="logoutBoxTitleS">{{ $t('chat.tuichudenglu') }}</div>
				<div class="logoutBoxBtns">
					<div class="logoutBoxBtnL" @click="logoutBoxShow=false">{{ $t('chat.quxiao') }}</div>
					<div class="logoutBoxBtnR" @click="logoutBtn">{{ $t('chat.queding') }}</div>
				</div>

			</div>
		</div>
		<canvas id="qrcanvas" class="qrcanvasBox"></canvas>
	</div>
</template>
<script>
import TIM from "tim-js-sdk";
import pinyin from 'js-pinyin'
import { getQrcodeUrl, checkQrcode, getQrcodeToken, 
	getUserFriendList, getUserGroupList, 
	getChatConfig, dataReport,chatLogout,
	getFriendInfo,getGroupInfo,getGroupUserList,
	getServerTime,recordLogger } from '@/api/chat';
import Crypto from "@/utils/encryp.js";
import { Toast } from 'vant';
import jsQR from "jsqr";
export default {
	name: "AppMain",
	props: {
		index: {
			type: String,
			default: '1'
		},
	},

	data() {
		return {
			// 高度缩小比例
			// scale: 1,

			// 用户相关
			uid: "",
			userInfo: {},
			token: "",

			isLogin: false,	// 是否登录
			chatText: '',
			// 搜索相关
			seaching: false,
			loading: true,
			seachText: '',  // 搜索内容
			seachNavIndex: 0, // 搜索类型 1.全部  2.好友 3.群聊
			seachNavItem: ['全部', '好友', '群聊'],
			seachListF: [], // 搜索结果 好友
			seachListG: [], // 搜索结果 群聊
			seachList: [], // 搜索结果 单独展示
			listType: 1,  // 列表类型 1.最近聊天  2.通讯录列表  3.加载中
			// 通讯录
			lettersArr: ['*', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '#'],
			directory: [],
			chatContentBox: 0, // 右侧聊天窗类型 0.无聊天 1.聊天 2.开始个人聊天 3.开始群聊天  4.加载中
			showUserMsg: false,
			qrcode_url: "", // 登录二维码
			qrcode_token: "", // 登录二维码对应token
			timer: null, // 登录二维码状态查询
			loginTpye: 0, // 二维码扫描状态 0 待扫描 1 已扫描 2 已确认 3已取消 100010 已过期
			smdasdf1: 'YzBleHFj', 
			smdasdf2: 'NG1ka', 
			smdasdf3: 'dHB5aW', 
			smdasdfS: 'F5', 
			smdasdfZ: 'w==',
			smdasdf11: 'b2Y3Zn',
			smdasdf17: 'zNtbXpveg==',
			smdasdf13: 'Q5b20xM',
			// 'of7ft9om133mmzoz'
			conversationList: [], // 聊天列表
			conversationList2: [], // 聊天列表-排序
			conversationIndex: 0,

			fLits: [], // 好友列表
			gLits: [], // 群列表
			allList: [], // 整合好友群列表
			directorySelect: undefined,
			chatingList: [], // 正在聊天消息列表
			selectFinfo: {},	// 选中的聊天好友信息
			selectFinfoList: [], // 选择过的聊天好友信息
			selectFmsg: [],	// 选中的聊天好友对话消息
			selectFmsgList: [], // 选择过的聊天好友对话消息

			// 搜索相关
			seachFlist: [], // 搜索匹配高亮 好友列表
			seachGlist: [], // 搜索匹配高亮 群列表

			// 视频相关
			playVideoBox:false,
			playVideoUrl:'',
			// 语音相关
			palyingVoice:false,
			palyVoice:'',
			clearBefor:'',
			voiceUrl:'',
			// 上传图片视频
			uploadImgUrl: '/upload',
			headers: {
				Authorization: ''
			},
			uploadData: {
				file_type: 'image',
				module: 'public',
				to_uid: undefined,
				group_id: undefined,
				is_original: undefined,
			},
			// 表情包对应
			emojiLits:[{id:1,text:"[微笑]"},{id:2,text:"[撇嘴]"},{id:3,text:"[色]"},{id:4,text:"[发呆]"},{id:5,text:"[酷]"},{id:6,text:"[流泪]"},{id:7,text:"[害羞]"},{id:8,text:"[闭嘴]"},{id:9,text:"[睡]"},{id:10,text:"[大哭]"},{id:11,text:"[尴尬]"},{id:12,text:"[发怒]"},{id:13,text:"[龇牙]"},{id:14,text:"[憨笑]"},{id:15,text:"[亲亲]"},{id:16,text:"[难过]"},{id:17,text:"[吐]"},{id:18,text:"[休闲]"},{id:19,text:"[大骂]"},{id:20,text:"[疑问]"},{id:21,text:"[嘘]"},{id:22,text:"[晕]"},{id:23,text:"[衰]"},{id:24,text:"[骷髅]"},{id:25,text:"[惊恐]"},{id:26,text:"[敲打]"},{id:27,text:"[再见]"},{id:28,text:"[汗]"},{id:29,text:"[挖孔]"},{id:30,text:"[坏笑]"},{id:31,text:"[哼哼]"},{id:32,text:"[偷笑]"},{id:33,text:"[鄙视]"},{id:34,text:"[委屈]"},{id:35,text:"[快哭了]"},{id:36,text:"[鼓掌]"},{id:37,text:"[阴险]"},{id:38,text:"[可怜]"},{id:39,text:"[生病]"},{id:40,text:"[笑哭]"},{id:41,text:"[恐惧]"},{id:42,text:"[失望]"},{id:43,text:"[无语]"},{id:44,text:"[嘿哈]"},{id:45,text:"[捂脸]"},{id:46,text:"[奸笑]"},{id:47,text:"[抱抱]"},{id:68,text:"[心碎]"},{id:69,text:"[大便]"},{id:70,text:"[拳头]"},{id:71,text:"[欧耶]"},{id:72,text:"[中指]"},{id:73,text:"[666]"},{id:74,text:"[biubiu]"},{id:75,text:"[ok]"},{id:76,text:"[不看好]"},{id:77,text:"[赞]"},{id:78,text:"[肌肉]"},{id:79,text:"[美甲]"},{id:80,text:"[握手]"},{id:81,text:"[爱你]"},{id:82,text:"[拍手]"},{id:83,text:"[祈祷]"},{id:84,text:"[挥手]"},{id:85,text:"[比心]"}],
			emojiShow: false,	// 显示表情盒子


			// 群聊相关
			selectGroupInfo: {},	// 选中的聊天群聊信息
			selectGroupInfoList: [], // 选择过的聊天群聊信息
			selectGroupMsg: {},	// 选中的聊天好友对话消息
			selectGroupMsgList: [], // 选择过的聊天好友对话消息
			addGroupMemberList: [], // 整合群成员列表
			addGroupMembering: false, // 添加防抖
			chatType:'f', //聊天类型  f单聊 g群聊
			// 群禁言 冻结相关

			// 是否有新消息
			hasNew:false,

			wordsList:[], // 敏感词 违禁词
			showQlaba:false,
			showQlabaData:{},
			showDeleteBtn:false,
			showDeleteBtnTop:0,
			showDeleteBtnleft:0,


			prefixF:'',
			prefixG:'',
			smdasdf:'',
			logoutBoxShow:false,
			isGoBottom:true,
			unreadCount:0,
			scale: 1,
			listRate:0,
		}
	},

	mounted() {
		
		const paramValue = this.$route.query.language;
    	console.log('参数值:', paramValue);
		this.$i18n.locale = this.$route.query.language
		
		sessionStorage.setItem('lang', this.$i18n.locale);
		// 高度适配
		if (window.innerHeight < 1000) {
			this.scale = 1 - (1000 - window.innerHeight) / 1000
		}
		// 测
		// this.prefixF='test_'
		// this.prefixG='test_group_'
		// this.smdasdf = decodeURIComponent(atob(this.smdasdf3+this.smdasdfS+this.smdasdf1+this.smdasdf2+this.smdasdfZ)) 

		// 正
		this.prefixF='release_'
		this.prefixG='release_group_'
		this.smdasdf = decodeURIComponent(atob(this.smdasdf11+this.smdasdf13+this.smdasdf17)) 

		this.init()
		this.getChatConfigBtn()
		// this.test()
	},
	beforeDestroy() {
		this.endTimer()
	},
	watch: {
		conversationList(newVal, oldVal) {
			let list = JSON.parse(JSON.stringify(this.conversationList))
			this.conversationList2 = this.sortedList(list)
			this.createSelectFmsg()
			this.createSelectGroupMsg()

			this.$forceUpdate()
			// console.log(this.conversationList2);
			// this.conversationList =newVal.sort((a, b) => {
			// 	if (a['send_time_int'] < b['send_time_int']) return -1
			// 	if (a['send_time_int'] > b['send_time_int']) return 1
			// 	return 0
			// })

		},
	},
	computed: {
		filteredfData() {
			return this.fLits.filter((post) => {
				return post.nickname.match(this.seachText)
			})
		}
	},

	methods: {
		async getServerTimeBtn(){
			const res = await getServerTime()
			return res.data.timestamp
		},

		async recordLoggerBtn(){
			let token = JSON.parse(sessionStorage.getItem("tokens")).token.access_token
			const res = await recordLogger({access_key:'web_chat_send_message',token})
		},
		// 文件转base 64
		getBase64(file) {
			return new Promise(function(resolve, reject) {
				let reader = new FileReader();
				let imgResult = "";
				reader.readAsDataURL(file);
				reader.onload = function() {
					imgResult = reader.result;
				};
				reader.onerror = function(error) {
					reject(error);
				};
				reader.onloadend = function() {
					resolve(imgResult);
				};
			});
		},
		
		updateUnread(){
			let NewAcount = 0
			this.conversationList2.map( (item) => {
				if (item.is_mute==0) {
					NewAcount += Number(item.unread) 
				}
			})
			this.unreadCount = NewAcount
		},

		infoTapTips(){
			Toast(this.$t('chat.bukegenghuan'));
		},
		seeUserMsg(item){
			// console.log(item.content.json.from_id);
			this.openPmsg(item.content.json.from_id)
		},
		deleteBtn(){
			console.log('删除删除');
		},
		rightClick(e,item){
			var container = this.$el.querySelector('#contentBox2');
			this.showDeleteBtn=true
			this.showDeleteBtnTop=e.y - container.offsetTop + 352
			this.showDeleteBtnleft=e.x - container.offsetLeft + 500
			console.log(item);
		},
		// 上报敏感词
		dataReportBtn(content){
			dataReport({ token: this.token,content }).then(res => {
				// console.log(res);
			})
		},
		// 获取敏感词 违禁词
		getChatConfigBtn(){
			getChatConfig({ token: this.token }).then(res => {
				this.wordsList = res.data.chat_tips
			})
		},
		hasNewBtn(){
			this.$nextTick(() => {
				var container = this.$el.querySelector('#chatMessageBox');
				container.scrollTop = container.scrollHeight;
			})
			this.hasNew = false

		},
		hasAt(item){
			let ret = false
			let that = this
			if(item.at_user){
				item.at_user.map( user => {
					if (user.uid==that.uid) {
						ret=true
					}
				})
			}
			
			return ret
		},
		exTipsBtnAt(){
			let top = this.$el.querySelectorAll('.chatMessageSelfAtmsg')[this.$el.querySelectorAll('.chatMessageSelfAtmsg').length-1].offsetTop
			console.log(top);
			this.$nextTick(() => {
				var container = this.$el.querySelector('#chatMessageBox');
				container.scrollTop = top;
			})
			// 消除信息内额外提示按钮
			for (let i = 0; i < this.selectGroupMsgList.length; i++) {
				const element = this.selectGroupMsgList[i];
				

				if (element.uid==this.selectGroupMsg.uid) {
					element.chatBoxExTips=0
				}
			}
			this.selectGroupMsg.chatBoxExTips=0
			for (let i = 0; i < this.conversationList2.length; i++) {
				const element = this.conversationList2[i];
				if (this.selectGroupMsg.uid == element.id) {
					element.exTips = 0
					// this.$forceUpdate()
					break
				}
			}
			this.$forceUpdate()
		},
		logoutBoxBtn(){
			this.logoutBoxShow = true
		},
		// 退出登录
		logoutBtn(){
			sessionStorage.removeItem("tokens")
			this.isLogin = false
			this.logoutBoxShow = false

			this.getQrCode()
			chatLogout({ token: this.token }).then(res => {
				console.log(res);
				this.$router.go(0)
			})
		},
		timestampToTime1(timestamp) {
			// 时间戳为10位需*1000，时间戳为13位不需乘1000
			var date = new Date(timestamp *1000);
			var Y = date.getFullYear() + "-";
			var M =
				(date.getMonth() + 1 < 10
				? "0" + (date.getMonth() + 1)
				: date.getMonth() + 1) + "-";
			var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
			var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours() ;
			var m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
			var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
			return Y + M + D + h + ":" + m + ":" + s;
		},

		// 表情相关
		changeText(text){
			// console.log(this.userInfo);
			let emojiLit = this.emojiLits
			// text=text.replace(/\n/g,'<p style="flex-wrap: wrap;"></p>')
			for (let i = 0; i < emojiLit.length; i++) {
				const emoji = emojiLit[i].text;
				let replaceIcon = '<span style="width: 21px;height: 21px;transform: translateY(7px);"><image style="width: 24px;height: 24px;transform: translateY(3px);" src="https://image.smdchat.com/emoji/face_routine_round_'+emojiLit[i].id+'.png"></image></span>'
				// let replaceIcon = `<p style="width: 28px;height: 28px;backgroundImage:'https://image.smdchat.com/emoji/face_routine_round_${emojiLit[i].id}.png';background-size: 28px 28px;" background-repeat: no-repeat;></p>`

				// 对于正则表达式构造函数，使用 flags 参数:new RegExp(' emoji  ', 'g')
				let re = new RegExp(this.escapeRegExp(emoji), 'g');

				text = text.replace(re,replaceIcon)

			}
			return text
		},
		escapeRegExp(string) {
			return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
		},
		tapEmoji(index){
			let emojiLit = this.emojiLits
			this.emojiShow = false
			this.chatText += emojiLit[index].text
			this.$nextTick(() => {
				this.$refs.ipt.focus()
			})
		},

		clickEmoji(t, e){
			getServerTime().then(res => {
				this.clickEmojiAfter(res.data.timestamp)
			})
			this.recordLoggerBtn()
		},
		clickEmojiAfter(time) {
			console.log(this.selectGroupInfo);
			if(this.chatType == 'g'){
				if(this.selectGroupInfo.can_use != 1){
					// 
					const timestamp = time*1000;

					if (this.selectGroupInfo.can_use==2) {
						// Toast(this.selectGroupInfo.no_speak_end_time);
						Toast(this.$t('chat.jinyongzhong'));
					}
					if (this.selectGroupInfo.can_use==3) {
						if (this.selectGroupInfo.lock_end_time=='永久') {
							Toast(this.$t('chat.yongjiudongjie'));
							return
						}else if (timestamp<this.selectGroupInfo.lock_end_time_int*1000) {
							Toast(this.$t('chat.dongjie')+this.selectGroupInfo.lock_end_time+this.$t('chat.jiechu'));
							return
						}else{
							this.updateGroupInfoBtn(this.selectGroupInfo.group_id)
						}
					}
					if (this.selectGroupInfo.can_use==4) {
						if (this.selectGroupInfo.no_speak_end_time=='永久') {
							Toast(this.$t('chat.yongjiujinyan'));
							return
						}else if (timestamp<this.selectGroupInfo.no_speak_end_time_int*1000) {
							Toast(this.$t('chat.jinyan')+this.selectGroupInfo.no_speak_end_time+this.$t('chat.jiechu'));
							return
						}else{
							this.updateGroupInfoBtn(this.selectGroupInfo.group_id)
						}
					}
				}else if(this.selectGroupInfo.user_no_speak !=0){
					const timestamp = time*1000;

					if (timestamp<this.selectGroupInfo.user_no_speak*1000) {
						// console.log('禁止发言');
						Toast(this.$t('chat.zhanghaojinyanz')+this.timestampToTime1(this.selectGroupInfo.user_no_speak)+this.$t('chat.jiechu'));
						return
					}else{
						this.updateGroupInfoBtn(this.selectGroupInfo.group_id)
					}
					
				}else if(this.selectGroupInfo.is_disabled ==1&&this.selectGroupInfo.is_root==0){
					// console.log('禁止发言');
					Toast(this.$t('chat.quanyuanjinyan'));
					return
				}
			}
			
			this.emojiShow = true
		},
		closeEmoji() {
			this.emojiShow = false
		},


		// 上传相关
		beforeUpload(file){
			getServerTime().then(res => {
				this.beforeUploadAfter(file,res.data.timestamp)
			})
			this.recordLoggerBtn()
		},

		beforeUploadAfter(file,time){
			let fileType = file.type.split('/')[0]
			console.log(file)
			console.log(fileType)

			if(this.chatType == 'g'){
				let dataG = this.selectGroupInfo

				if(dataG.can_use != 1){
					// 
					const timestamp = time*1000;

					if (dataG.can_use==2) {
						// Toast(this.selectGroupInfo.no_speak_end_time);
						Toast(this.$t('chat.jinyongzhong'));
						return false;
					}
					if (dataG.can_use==3) {
						if (dataG.lock_end_time=='永久') {
							Toast(this.$t('chat.yongjiudongjie'));
							return false;
						}else if (timestamp<dataG.lock_end_time_int*1000) {
							Toast(this.$t('chat.dongjie')+dataG.lock_end_time+this.$t('chat.jiechu'));
							return false;
						}else{
							this.updateGroupInfoBtn(dataG.group_id)
						}
					}
					if (dataG.can_use==4) {
						if (dataG.no_speak_end_time=='永久') {
							Toast(this.$t('chat.yongjiujinyan'));
							return false;
						}else if (timestamp<dataG.no_speak_end_time_int*1000) {
							Toast(this.$t('chat.jinyan')+dataG.no_speak_end_time+this.$t('chat.jiechu'));
							return false;
						}else{
							this.updateGroupInfoBtn(dataG.group_id)
						}
					}
				}else if(dataG.user_no_speak !=0){
					Toast(this.$t('chat.zhanghaojinyanz')+this.timestampToTime1(dataG.user_no_speak)+this.$t('chat.jiechu'));
					return false;
				}else if(dataG.is_disabled ==1&&dataG.is_root==0){
					Toast(this.$t('chat.quanyuanjinyan'));
					return false;
				}
				if (dataG.is_root==0&&dataG.use_manager==1&&dataG.manager_config.can_use==1) {
					if (dataG.manager_config.chat_disable_video==1&&fileType=='video') {
						Toast(this.$t('chat.xxsbqunguanji'));
						return false;
					}else if (dataG.manager_config.chat_disable_img==1&&fileType=='image') {
						Toast(this.$t('chat.xxsbqunguanji'));
						return false;
					}
					
				} 
			}
			


			this.headers.Authorization =this.token
			this.uploadData.file_type = file.type.split('/')[0]
			console.log(this.selectFmsg)
			if (this.chatType=='f') {
				this.uploadData.module = 'chat'	
				this.uploadData.to_uid = this.selectFmsg.data.id

			}else if (this.chatType=='g') {
				this.uploadData.module = 'group'	
				this.uploadData.group_id = this.selectGroupMsg.data.id
			}
		},
		
		uploadAfterMsg(newFile){
			getServerTime().then(res => {
				this.uploadAfterMsgAfter(newFile,res.data.timestamp)
			})
		},
		uploadAfterMsgAfter(newFile,time){
			
			let content = {
				"height": newFile.additional.height,
				"width": newFile.additional.width,
				"isShowEncryption": 0,
				"is_original": 0,
				"local_path": "",
				"original_size": newFile.additional.filesize_format,
				"original_thumb_size": 0,
				"path": newFile.file_url,
				"size": newFile.additional.filesize,
				"thumb_height": 0,
				"thumb_path": newFile.additional.file_thumb_url,
				"thumb_width": 0,
				"type": this.uploadData.file_type == 'image'? 'picture' : 'video',
				"url": newFile.url,
			}

			// console.log(this.userInfo);
			// console.log(this.selectFmsg);
			// console.log(this.uploadData.file_type);
			// console.log(this.selectGroupMsg);
			let type = this.chatType
			let data = {
				content: {
					action: type == 'f' ? "asdf" : "asdf_group",
					json: {
						content:JSON.stringify(content),
						expire_time: 0,
						font_color: this.userInfo.bubble_font_colour,
						friend_id: 0,
						fromLogo: this.userInfo.avatar,
						fromName: this.userInfo.nickname,
						from_id: this.userInfo.uid,
						is_beautiful_name: this.userInfo.is_beautiful_name,
						is_private: 0,
						is_read: 0,
						is_real: this.userInfo.is_real,
						msg_bg_id: this.userInfo.bubble_id,
						msg_id: "",
						q_id: this.userInfo.unique_id,
						send_time_int: 0,
						show_top: 0,
						toLogo:type == 'f' ? this.selectFmsg.data.avatar:this.selectGroupMsg.data.avatar,
						toName: type == 'f' ?this.selectFmsg.data.name:this.selectGroupMsg.data.name,
						to_id: type == 'f' ? this.selectFmsg.data.id : 0,
						to_gid: type == 'f' ? 0 : this.selectGroupMsg.data.id,
						type: this.uploadData.file_type == 'image'? 'picture' : 'video',
						vip_honor_type: this.userInfo.vip_honor_type,
						vip_type: this.userInfo.vip_type,
					}
				},
				type: "TxtMsg"
			}
			// 4位随机数
			const charsArray = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
			let randomCode = '';
			for (let i = 0; i < 4; i++) {
				const randomCharIndex = Math.floor(Math.random() * charsArray.length);
				randomCode += charsArray[randomCharIndex];
			}
			let nowDate2 = new Date(time).getTime()
			data.content.json.send_time_int = nowDate2
			let nowDate = JSON.stringify(nowDate2)
			data.content.json.msg_id = nowDate + "_" + this.uid + "_pc_" + randomCode
			if (this.chatType == 'f') {
				this.sendSay(data, this.prefixF + JSON.stringify(this.selectFmsg.data.id),1)
			}else if(this.chatType == 'g'){
				this.sendSayGroup(data, this.prefixG + JSON.stringify(this.selectGroupMsg.data.id),1)

			}
		},
		uploadEditorSuccess(res, file) {
			// console.log('上传成功-发送到im')
			console.log(res)
			console.log(file)
			if (res.code!=200) {
				Toast(this.$t('chat.buyunxushangc'));
				return
			}
			let newFile = res.data.list[0]
			let fileType = file.raw.type.split('/')[0]
			if (fileType=='image'&&this.chatType=='g') {
				let dataG = this.selectGroupInfo
				if (dataG.is_root==0&&dataG.use_manager==1&&dataG.manager_config.can_use==1&&dataG.manager_config.chat_disable_qr==1) {
					// console.log('进二维码检测');
					let _this = this;
					this.getBase64(file.raw).then((res) => {
						let img = new Image();
						img.src = res;
						img.onload = function() {
						//  base64
						var c = document.getElementById("qrcanvas");
						var ctx = c.getContext("2d");

						//  记一个坑，这里高度得除以2 , 图片会拉伸
						ctx.drawImage(img, 0, 0, img.width, img.height / 2);
						var imageData = ctx.getImageData(0, 0, img.width, img.height);

						// 再进行识别
						const code = jsQR(imageData.data, imageData.width, imageData.height);

						if (code) {
							console.log("Found QR code:", code.data);
							Toast(this.$t('chat.xxsbqunguanji'));

						} else {
							// _this.$message("图片无对应二维码");
							_this.uploadAfterMsg(newFile)

						}
						};
					});
				}else{
					this.uploadAfterMsg(newFile)

				}
			}else{
				this.uploadAfterMsg(newFile)

			}

		},
		tapUnreadable(){
			Toast(this.$t('chat.qianwangappkan'));
		},
		playVoice(itemContent,lastId){
			let that = this
			if (that.palyingVoice) {
				this.$refs.audio.pause();
				this.selectFmsgList.map(res =>{
					if (res.uid==lastId) {
						res.list.map( data =>{
							if (data.content.json.type=='voice') {
								data.content.json.content.now = 0
								data.content.json.content.play = false 
								that.$forceUpdate()
							}
						})
					}
				})
				this.selectGroupMsgList.map(res =>{
					console.log(lastId);
					console.log(res);

					if (res.uid==lastId) {
						res.list.map( data =>{
							if (data.content.json.type=='voice') {
								data.content.json.content.now = 0
								data.content.json.content.play = false 
								that.$forceUpdate()
							}
						})
					}
				})
				clearInterval(that.palyVoice)
			}

			itemContent.now = 0
			this.$refs.audio.src = itemContent.url + itemContent.path;
			this.$refs.audio.play();
			that.palyVoice = setInterval(() => {
				that.palyingVoice = true
				itemContent.now = itemContent.now + 0.1
				if (itemContent.now>=itemContent.length) {
					itemContent.now = 0
					that.palyingVoice = false
					itemContent.play = false 
					clearInterval(that.palyVoice)
				}
				that.$forceUpdate()

			}, 100);

			
			that.palyVoice
			itemContent.play=true
		},
		closeVideoBox(){
			// 关闭视频
			this.playVideoBox = false
			// 停止播放
			this.$refs.videoRef.pause();//暂停
		},
		handleTimeUpdate(){
			// console.log('播放完毕');
		},
		// 播放视频
		playVideo(url){
			this.playVideoBox = true
			this.playVideoUrl = url
		},

		
		// 切换成搜索
		gotoSeach() {
			this.seaching = true
			this.seachText = ''
			this.seachFlist = this.fLits
			this.seachGlist = this.gLits
			this.seachNavIndex = 0
			this.$nextTick(() => {
				this.$refs.seachInput.focus()
			});
		},
		// 搜索 高亮匹配
		startSeach() {
			this.replaceText(1)
			this.replaceText(2)
		},
		// 高亮匹配
		replaceText(type, newList) {
			// 去除恶意输入代码片段的可能
			let value = this.seachText.replace(/<.*?>/gi, "");
			// 修改高亮文案
			let dataList = [];
			// 声明正则校验  (正则表达式,"匹配模式")  /gi(全文查找、忽略大小写)
			const reg = new RegExp(value, "gi");
			// 循环遍历  this.list 使用数据进行渲染  resultList 使用只符合条件的数据
			if (type == 1) {
				this.fLits.forEach((item) => {
					let obj = {
						avatar: item.avatar,
						uid: item.uid,
						nickname: item.nickname.replace(
							reg,
							//筛选出来的文字加样式
							(val) => `<span style='color:#3486FF'>${val}</span>`
						),
					};
					if (obj.nickname.indexOf("color:#3486FF") != -1) {
						dataList.push(obj);
					}
				});
				// 赋值
				this.seachFlist = dataList;
			} else if (type == 2) {
				this.gLits.forEach((item) => {
					let obj = {
						can_use: item.can_use,
						group_id: item.group_id,
						lock_end_time: item.lock_end_time,
						lock_end_time_int: item.lock_end_time_int,
						logo: item.logo,
						unique_id: item.unique_id,
						groupname: item.groupname.replace(
							reg,
							//筛选出来的文字加样式
							(val) => `<span style='color:#3486FF'>${val}</span>`
						),
					};
					if (obj.groupname.indexOf("color:#3486FF") != -1) {
						dataList.push(obj);
					}
				});
				// 赋值
				this.seachGlist = dataList;
			}

		},

		highlightMatches(text) {
			return text.replace(new RegExp(this.seachText, "gi"), match => {
				return '' + match + ''
			})
		},
		// 删除会话
		deleteConversationBtn(row) {
			let that = this
			console.log(row.id);
			// id 格式
			let delId = 'C2C'+ this.prefixF + row.id
			if (row.type == 'g') {
				delId = "GROUP"+ this.prefixG + row.id
			}
			let promise = this.$tim.deleteConversation(delId);
			promise.then(function (imResponse) {
				// 删除聊天列表
				for (let i = 0; i < that.conversationList2.length; i++) {
					const element = that.conversationList2[i];
					if (element.id == row.id) {
						that.conversationList2.splice(i, 1)
						break
					}
				}
				for (let i = 0; i < that.selectFmsgList.length; i++) {
					const element = that.selectFmsgList[i];
					if (element.uid == row.id) {
						element.list = []
						break
					}
				}
				that.chatContentBox = 0
				// 删除会话成功
				const { conversationID } = imResponse.data; // 被删除的会话 ID
			}).catch(function (imError) {
				console.warn('deleteConversation error:', imError); // 删除会话失败的相关信息
			});
		},
		// 删除消息 
		deleteMessageBtn(ids,data){
			let that = this 
			console.log(ids);
			// 删除消息成功
			for (let i = 0; i < that.selectFmsgList.length; i++) {
				const element = that.selectFmsgList[i];
				if (data.friend_id==element.uid) {
					console.log(element);
					console.log(data.friend_id);

					// 获取删除索引
					let delIndex  = []
					for (let j = 0; j < element.list.length; j++) {
						const element1 = element.list[j];
						ids.map(id => {
							if (id==element1.content.json.msg_id) {
								delIndex.push(j)
							}
						})
					}
					console.log(delIndex);
					// 删除列表对应项
					let newArr = []
					for (let j = 0; j < element.list.length; j++) {
						const element1 = element.list[j];
						let noDel = true
						delIndex.map(index => {
							if (index==j) {
								noDel = false
								if (j==element.list.length-1) {
									console.log(that.conversationList2);
									that.conversationList2.map(item => {
										if (item.id==data.friend_id) {
											if (element.list[j-1]) {
												let type = element.list[j-1].content.json.type
												if (type == 'text') {
													item.content = element.list[j-1].content.json.content
												} else if (type == 'picture'||type == 'emoticon') {
													item.content = that.$t('chat.tupianxx')
												} else if (type == 'video') {
													item.content = that.$t('chat.shipinxx')
												} else if (type == 'voice') {
													item.content = that.$t('chat.yuyinxx')
												} else {
													item.content = that.$t('chat.qitaxx')
												}
												item.send_time_int=element.list[j-1].content.json.send_time_int
											}else{
												item.content=''
											}
											
										}
									})
									console.log(element.list[j-1])
								}
							}
							
						})
						if (noDel) {
							newArr.push(element1)
						}
					}
					element.list=newArr
					that.$forceUpdate()
				}
			}

			return
			let promise = this.$tim.deleteMessage(ids);
			promise.then(function(imResponse) {
				// 删除消息成功
				for (let i = 0; i < that.selectFmsgList.length; i++) {
					const element = that.selectFmsgList[i];
					if (data.friend_id==element.uid) {
						// 获取删除索引
						let delIndex  = []
						for (let j = 0; j < element.list.length; j++) {
							const element1 = element.list[j];
							ids.map(id => {
								if (id==element1.msg_id) {
									delIndex.push(j)
								}
							})
						}
						// 删除列表对应项
						let newArr = []
						for (let j = 0; j < element.list.length; j++) {
							const element1 = element.list[j];
							let noDel = true
							delIndex.map(index => {
								if (index==j) {
									noDel = false
								}
							})
							if (noDel) {
								newArr.push(element1)
							}
						}
						element.list=newArr
					}
				}
			}).catch(function(imError) {
				// 删除消息失败
				console.warn('deleteMessage error:', imError);
			});
		},
		// 删除消息 
		deleteMessageBtn_group(ids,data){
			let that = this 
			console.log(ids);
			// 删除消息成功
			for (let i = 0; i < that.selectGroupMsgList.length; i++) {
				const element = that.selectGroupMsgList[i];
				if (data.group_id==element.uid) {
					console.log(element);
					console.log(data.group_id);

					// 获取删除索引
					let delIndex  = []
					for (let j = 0; j < element.list.length; j++) {
						const element1 = element.list[j];
						ids.map(id => {
							if (id==element1.content.json.msg_id) {
								delIndex.push(j)
							}
						})
					}
					console.log(delIndex);
					// 删除列表对应项
					let newArr = []
					for (let j = 0; j < element.list.length; j++) {
						const element1 = element.list[j];
						let noDel = true
						delIndex.map(index => {
							if (index==j) {
								noDel = false
								if (j==element.list.length-1) {
									console.log(that.conversationList2);
									that.conversationList2.map(item => {
										if (item.id==data.group_id) {
											if (element.list[j-1]) {
												console.log(element.list[j-1].content.json);
												let fromName = element.list[j-1].content.json.fromName
												let type = element.list[j-1].content.json.type
												if (type == 'text') {
													item.content = fromName + ' : ' + element.list[j-1].content.json.content
												} else if (type == 'at_rich_text') {
													item.content = fromName + ' : ' + JSON.parse(element.list[j-1].content.json.content).content
												} else if (type == 'picture'||type == 'emoticon') {
													item.content = fromName + ' : ' + that.$t('chat.tupianxx')
												} else if (type == 'video') {
													item.content = fromName + ' : ' + that.$t('chat.shipinxx')
												} else if (type == 'voice') {
													item.content = fromName + ' : ' + that.$t('chat.yuyinxx')
												} else {
													item.content = fromName + ' : ' + that.$t('chat.qitaxx')
												}
												item.send_time_int=element.list[j-1].content.json.send_time_int
											}else{
												item.content=''
											}
											
										}
									})
									console.log(element.list[j-1])
								}
							}
							
						})
						if (noDel) {
							newArr.push(element1)
						}
					}
					element.list=newArr
					that.$forceUpdate()
				}
			}

		},
		
		createSelectFmsg() {
			for (let i = 0; i < this.conversationList.length; i++) {
				const clist = this.conversationList[i];
				let has = 0
				for (let j = 0; j < this.selectFmsgList.length; j++) {
					const talkList = this.selectFmsgList[j];
					if (talkList.uid == clist.id) {
						has = 1
					}
				}
				
				this.$nextTick(() => {
					if (has == 0) {
						let pushitem = {
							uid: clist.id,
							name: clist.name,
							data: clist,
							list: [JSON.parse(JSON.stringify(clist.originalData))],
						}
						this.selectFmsgList.push(pushitem)
					}
				});

			}

		},
		createSelectGroupMsg(){
			for (let i = 0; i < this.conversationList.length; i++) {
				const clist = this.conversationList[i];
				let has = 0
				for (let j = 0; j < this.selectGroupMsgList.length; j++) {
					const talkList = this.selectGroupMsgList[j];
					if (talkList.uid == clist.id) {
						has = 1
					}
				}
				
				this.$nextTick(() => {
					if (has == 0) {
						let pushitem = {
							uid: clist.id,
							name: clist.name,
							data: clist,
							list: [JSON.parse(JSON.stringify(clist.originalData))],
						}
						this.selectGroupMsgList.push(pushitem)
					}
				});

			}
		},
		// 更新群详情
		updateGroupInfoBtn(group_id){
			getGroupInfo({ group_id, token: this.token }).then(res => {
				console.log(res.data);
				let ishas = 0
				for (let i = 0; i < this.selectGroupInfoList.length; i++) {
					const element = this.selectGroupInfoList[i];
					console.log(element);
					if (group_id==element.group_id) {
						for (const key in element) {
							if (Object.hasOwnProperty.call(element, key)) {
								element[key]=res.data[key]
							}
						}
						if (this.selectGroupInfo.group_id==group_id) {
							this.selectGroupInfo=element
						}
						break
					}
				}
				console.log(this.selectGroupInfoList);

			})
		},

		// 获取群详情
		getGroupInfoBtn(group_id,showbox3) {
			getGroupInfo({ group_id, token: this.token }).then(res => {
				this.addGroupMembering = true
				this.getGroupMenberBtn(group_id,1,showbox3)
				this.selectGroupInfo = res.data
				this.selectGroupInfo.userListPage = 1
				if (!this.selectGroupInfo.list) {
					this.selectGroupInfo.list = []
				}
				// console.log(this.selectGroupInfo);
				this.selectGroupInfoList.push(this.selectGroupInfo)

				return
				this.addGroupMemberList=[]
				this.addGroupMembering = true
				this.selectGroupInfo = res.data
				this.selectGroupInfo.userListPage = 1
				this.selectGroupInfo.list = []

				this.getGroupMenberBtn(group_id,1,showbox3)

				this.selectGroupInfoList.push(res.data)

			})
		},
		getGroupMenberBtnMore(){
			let group = this.selectGroupInfo
			this.addGroupMembering = true

			getGroupUserList({ group_id:group.group_id, token: this.token ,pagesize:28,page:group.userListPage+1}).then(res => {
				let newList = [...group.list,...res.data.list]
				this.selectGroupInfo.list = newList
				if (newList.length<res.data.pagination.total) {
					this.selectGroupInfo.hasNext = true
				}else{
					this.selectGroupInfo.hasNext = false
				}
				this.selectGroupInfo.userListPage += 1
				this.selectGroupInfoList.map(item => {
					if (item.group_id==group.group_id) {
						item = this.selectGroupInfo
						this.$forceUpdate()
					}
				})
				this.addGroupMembering = false

			})

		},

		getGroupMenberBtn(group_id,page,showbox3){
			getGroupUserList({ group_id, token: this.token ,pagesize:28,page}).then(res => {
				let newList = [...this.selectGroupInfo.list,...res.data.list]

				this.selectGroupInfo.list = newList
				if (newList.length<res.data.pagination.total) {
					this.selectGroupInfo.hasNext = true
				}

				this.selectGroupInfoList.map(item => {
					if (item.group_id==group_id) {
						item = this.selectGroupInfo
						item.total =res.data.pagination.total
						this.$forceUpdate()
					}
				})

				this.addGroupMembering = false
				if (showbox3) {
					this.chatContentBox = 3
				}

				return
				this.addGroupMemberList=[...this.addGroupMemberList,...res.data.list]
				// console.log(this.addGroupMemberList);
				if (res.data.list.length>=1000) {
					console.log(page+1);
					this.getGroupMenberBtn(group_id,page+1,showbox3)
				}else{
					this.addGroupMembering = false
					this.selectGroupInfo.list = this.addGroupMemberList
					this.selectGroupInfoList.map(item => {
						if (item.group_id==group_id) {
							item.list =this.addGroupMemberList
							item.total =res.data.pagination.total
							this.$forceUpdate()
						}
					})
					if (showbox3) {
						this.chatContentBox = 3
					}
				}
			})
		},
		getFriendInfoBtn(friend_id) {
			getFriendInfo({ friend_id, token: this.token }).then(res => {
				if (friend_id == this.uid) {
					// 用户本人信息
					// console.log(res);
					this.userInfo = res.data
				} else {
					this.selectFinfo = res.data
					this.selectFinfoList.push(res.data)
				}
			})
		},
		sortedList(list) {
			return list.sort((a, b) => {
				if (a['send_time_int'] < b['send_time_int']) return 1
				if (a['send_time_int'] > b['send_time_int']) return -1
				return 0
			})
		},
		getList() {
			this.getFriendInfoBtn(this.uid)
			this.getNextPageFriend(0)
		},
		getNextPageFriend(nowPage){
			getUserFriendList({ pagesize: 1000, token: this.token,page: nowPage+1 }).then(res => {
				let arr = []
				for (let i = 0; i < res.data.list.length; i++) {
					const element = res.data.list[i];
					arr.push({
						type: "f",
						avatar: element.avatar,
						id: element.uid,
						name: element.nickname,
						send_time_int: 0,
						unread: 0,
					})
				}
				// console.log(this.allList);
				this.allList = this.allList.concat(arr);
				this.fLits = [...this.fLits,...res.data.list]
				// this.listRate = ((nowPage+1)/res.data.pagination.total_page).toFixed(2) *100
				this.listRate = Math.floor(((nowPage+1)/res.data.pagination.total_page)*100)
				// console.log(this.listRate);
				if (res.data.pagination.total_page==nowPage+1||res.data.pagination.total_page==0) {
					this.getFirstPin(this.allList)
					this.getUserGroupListBtn()
				}else{
					this.getNextPageFriend(nowPage+1)
				}
			})
		},
		getUserGroupListBtn(){
			getUserGroupList({ token: this.token }).then(res => {
				let arr = []
				for (let i = 0; i < res.data.length; i++) {
					const element = res.data[i];
					arr.push({
						type: "g",
						avatar: element.logo,
						id: element.group_id,
						name: element.groupname,
						send_time_int: 0,
						unread: 0,
						unique_id: element.unique_id,
						can_use: element.can_use,
						is_mute: element.is_mute,
						is_root: element.is_root,
						is_save: element.is_save,
						lock_end_time: element.lock_end_time,
						lock_end_time_int: element.lock_end_time_int,
						no_speak_end_time: element.no_speak_end_time,
						no_speak_end_time_int: element.no_speak_end_time_int,
						user_no_speak: element.user_no_speak,

					})
				}
				this.allList = this.allList.concat(arr);
				let group_qunzhu = []
				let group_guanli = []
				let group_chengyuan = []
				res.data.map(item => {
					if (item.is_root==2) {
						group_qunzhu.push(item)
					}else if(item.is_root==1){
						group_guanli.push(item)
					}else if(item.is_root==0){
						group_chengyuan.push(item)
					}
				})
				this.gLits = group_qunzhu.concat(group_guanli.concat(group_chengyuan))
				this.loading = false
				this.getLastChatList()
			})
		},
		
		timestampToTime(timestamp) {

			var date = new Date(timestamp);
			var Y = date.getFullYear() + "-";
			var M =
				(date.getMonth() + 1 < 10
					? "0" + (date.getMonth() + 1)
					: date.getMonth() + 1);
			var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
			var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
			var m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
			var s = ":" + date.getSeconds();
			if (new Date(timestamp).toDateString() === new Date().toDateString()) {
				var hf = this.$t('chat.shangwu')
				if (h > 12) {
					hf = this.$t('chat.xiawu')
				}

				return hf + " " + h + ":" + m;

			} else if (new Date(timestamp) < new Date()) {

				return M + this.$t('chat.yue') + D + this.$t('chat.ri');

			}
		},
		// 切换聊天
		changeChat(id, row) {
			this.chatContentBox = 4
			this.chatingList = []
			this.chatText = ""
			console.log(row);
			this.chatType=row.type

			this.hasNew = false
			this.showQlaba = false
			if (row.type == 'f') {
				this.selectGroupMsg={}
				// 获取好友信息
				let is_hasF = 0
				for (let i = 0; i < this.selectFinfoList.length; i++) {
					const element = this.selectFinfoList[i];
					if (row.id == element.uid) {
						this.selectFinfo = element
						is_hasF = 1
						break
					}
				}
				if (is_hasF == 0) {
					this.getFriendInfoBtn(row.id)
				}
				// 渲染好友聊天列表
				let is_hasChat = 0

				for (let i = 0; i < this.selectFmsgList.length; i++) {
					const element = this.selectFmsgList[i];
					if (row.id == element.uid) {
						// console.log(element);
						this.selectFmsg = element
						this.$forceUpdate()
						is_hasChat = 1
						break
					}
				}
				if (is_hasChat == 0) {
					let pushitem = {
						uid: row.id,
						name: row.name,
						data: row,
						list: [row.originalData],
					}
					this.selectFmsgList.push(pushitem)
					this.selectFmsg = pushitem

				}
			}else if(row.type == 'g'){
				this.selectFmsg={}
				
				console.log(this.selectGroupInfo);
				// 获取群信息
				let is_hasG = 0
				for (let i = 0; i < this.selectGroupInfoList.length; i++) {
					const element = this.selectGroupInfoList[i];
					if (row.id == element.group_id) {
						this.selectGroupInfo = element
						is_hasG = 1
						break
					}
				}
				if (is_hasG == 0) {
					this.getGroupInfoBtn(row.id,false)
				}
				// 渲染好友聊天列表
				let is_hasChat = 0

				for (let i = 0; i < this.selectGroupMsgList.length; i++) {
					const element = this.selectGroupMsgList[i];
					if (row.id == element.uid) {
						this.selectGroupMsg = element
						this.$forceUpdate()
						is_hasChat = 1
						break
					}
				}
				if (is_hasChat == 0) {
					let pushitem = {
						uid: row.id,
						name: row.name,
						data: row,
						list: [row.originalData],
					}
					this.selectGroupMsgList.push(pushitem)
					this.selectGroupMsg = pushitem

				}
				console.log(this.selectGroupMsg);
			}
			// 消除信息红点 和 额外提示
			for (let i = 0; i < this.conversationList2.length; i++) {
				const element = this.conversationList2[i];
				if (row.id == element.id) {
					element.unread = 0
					element.exTips = 0
					this.updateUnread()
					// this.$forceUpdate()
					break
				}
			}
			

			this.conversationIndex = id
			this.chatContentBox = 1
			setTimeout(() => {
				this.$nextTick(() => {
					var container = this.$el.querySelector('#chatMessageBox');
					container.scrollTop = container.scrollHeight;
					this.$refs.ipt.focus()
					this.isGoBottom =true
					container.addEventListener('scroll', this.handleScroll)
				})
			}, 100);
			
		},
		handleScroll(){
			var container = this.$el.querySelector('#chatMessageBox');

			if ((container.scrollHeight - container.scrollTop) == 439 ) {
				this.hasNew = false
				this.isGoBottom = true

			}else{
				this.isGoBottom = false

			}
		},
		
		async adductMsg(){
			
			return new Promise(async (resolve, reject) => {
				try {
				const res = await getServerTime();
				const data = this.adductMsgAfter(res.data.timestamp);
				this.recordLoggerBtn();
				resolve(data); 
				} catch (error) {
				console.error('Error in adductMsg:', error);
				reject(error); 
				}
			});
		},
		// 整合消息体
		adductMsgAfter(time) {
			console.log(this.selectFmsg.data.type == 'f');
			console.log(this.userInfo);
			console.log(this.selectFmsg);
			let type = this.selectFmsg.data.type
			// 离线消息体内容：您收到一条事密达加密消息
			let data = {
				content: {
					action: "asdf" ,
					json: {
						content: this.chatText,
						expire_time: 0,
						font_color: this.userInfo.bubble_font_colour,
						friend_id: 0,
						fromLogo: this.userInfo.avatar,
						fromName: this.userInfo.nickname,
						from_id: this.userInfo.uid,
						is_beautiful_name: this.userInfo.is_beautiful_name,
						is_private: 0,
						is_read: 0,
						is_real: this.userInfo.is_real,
						msg_bg_id: this.userInfo.bubble_id,
						msg_id: "",
						q_id: this.userInfo.unique_id,
						send_time_int: 0,
						show_top: 0,
						toLogo: this.selectFmsg.data.avatar,
						toName: this.selectFmsg.data.name,
						to_id: type == 'f' ? this.selectFmsg.data.id : 0,
						to_gid: type == 'f' ? 0 : this.selectFmsg.data.id,
						type: "text",
						vip_honor_type: this.userInfo.vip_honor_type,
						vip_type: this.userInfo.vip_type,
					}
				},
				type: "TxtMsg"
			}
			// console.log(this.selectFinfo);
			// console.log(this.selectFmsg.data);

			// 4位随机数
			const charsArray = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
			let randomCode = '';
			for (let i = 0; i < 4; i++) {
				const randomCharIndex = Math.floor(Math.random() * charsArray.length);
				randomCode += charsArray[randomCharIndex];
			}
			
			let nowDate2 = new Date(time).getTime()
			data.content.json.send_time_int = nowDate2
			let nowDate = JSON.stringify(nowDate2)
			data.content.json.msg_id = nowDate + "_" + this.uid + "_pc_" + randomCode


			return data
		},
		async adductMsgGroup(){
			
			return new Promise(async (resolve, reject) => {
				try {
				const res = await getServerTime();
				const data = this.adductMsgGroupAfter(res.data.timestamp);
				this.recordLoggerBtn();
				resolve(data); 
				} catch (error) {
				console.error('Error in adductMsg:', error);
				reject(error); 
				}
			});
		},
		// 整合消息体 群聊的
		adductMsgGroupAfter(time) {
			console.log(this.selectGroupMsg);

			let data = {
				content: {
					action:  "asdf_group",
					json: {
						content: this.chatText,
						expire_time: 0,
						font_color: this.userInfo.bubble_font_colour,
						friend_id: 0,
						fromLogo: this.userInfo.avatar,
						fromName: this.userInfo.nickname,
						from_id: this.userInfo.uid,
						is_beautiful_name: this.userInfo.is_beautiful_name,
						is_private: 0,
						is_read: 0,
						is_real: this.userInfo.is_real,
						msg_bg_id: this.userInfo.bubble_id,
						msg_id: "",
						q_id: this.userInfo.unique_id,
						send_time_int: 0,
						show_top: 0,
						toLogo: this.selectGroupMsg.data.avatar,
						toName: this.selectGroupMsg.data.name,
						to_id:  0,
						to_gid:  this.selectGroupMsg.data.id,
						type: "text",
						vip_honor_type: this.userInfo.vip_honor_type,
						vip_type: this.userInfo.vip_type,
					}
				},
				type: "TxtMsg"
			}

			// 4位随机数
			const charsArray = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
			let randomCode = '';
			for (let i = 0; i < 4; i++) {
				const randomCharIndex = Math.floor(Math.random() * charsArray.length);
				randomCode += charsArray[randomCharIndex];
			}
			
			let nowDate2 = new Date(time).getTime()
			data.content.json.send_time_int = nowDate2
			let nowDate = JSON.stringify(nowDate2)
			data.content.json.msg_id = nowDate + "_" + this.uid + "_pc_" + randomCode

			return data
		},

		getUserToken() {
			// 获取用户二维码及IM相关
			
			getQrcodeToken({ token: this.qrcode_token }).then(res => {
				// console.log(res);
				let im_token = res.data.im_token
				var expireDate = new Date().getTime() + res.data.token.expires_in * 1000;
				res.data.expireDate = expireDate
				this.uid = res.data.uid = im_token.userID.split("_")[1]
				this.token = res.data.token.access_token
				sessionStorage.setItem("tokens", JSON.stringify(res.data))
				// this.getList()
				this.timLogin(im_token.userID, im_token.userSig)
				this.monitor()
			})
		},
		// 获取最近聊天
		async getLastMsgNext(conversationID, nextReqMessageID, item1) {
			let that = this
			let content = ""
			let send_time_int = ""
			let type = ""
			let fromName = ""
			let from_id = ""
			let originalData = ""

			let promise = that.$tim.getMessageList({ conversationID, nextReqMessageID });
			await promise.then(function (msg) {
				msg.data.messageList.map(msgitem => {
					let data = ""
					if (Crypto.decrypt(msgitem.payload.data, that.smdasdf)) {
						try {
							data = JSON.parse(Crypto.decrypt(msgitem.payload.data, that.smdasdf))
						} catch (error) {
							
						}
					}
					// if (data.type == "TxtMsg" && (data.content.action=='asdf_group'||data.content.action=='asdf')) {
					if (data.type == "TxtMsg" && data.content.json.content) {
						content = data.content.json.content
						send_time_int = data.content.json.send_time_int
						type = data.content.json.type
						fromName = data.content.json.fromName
						from_id = data.content.json.from_id
						if (data.content.json.type != 'text'&& data.content.json.type != 'friend_tips') {
							data.content.json.content = JSON.parse(data.content.json.content)
						}
						originalData = data
					}
				})
				if (content != "") {
					for (let i = 0; i < that.conversationList.length; i++) {
						const element = that.conversationList[i];
						if (element.id == item1.id) {
							if (item1.type = "f") {
								if (type == 'text') {
									element.content = content
								} else if (type == 'picture'||type == 'emoticon') {
									element.content = that.$t('chat.tupianxx')
								} else if (type == 'video') {
									element.content = that.$t('chat.shipinxx')
								} else if (type == 'voice') {
									element.content = that.$t('chat.yuyinxx')
								} else {
									element.content = that.$t('chat.qitaxx')
								}

							} else if (item1.type = "g") {
								if (type == 'text') {
									item1.content = fromName + ' : ' + content
								} else if (type == 'at_rich_text') {
									item1.content = fromName + ' : ' + JSON.parse(content).content
								} else if (type == 'picture'||type == 'emoticon') {
									item1.content = fromName + ' : ' + that.$t('chat.tupianxx')
								} else if (type == 'video') {
									item1.content = fromName + ' : ' + that.$t('chat.shipinxx')
								} else if (type == 'voice') {
									item1.content = fromName + ' : ' + that.$t('chat.yuyinxx')
								} else {
									item1.content = fromName + ' : ' + that.$t('chat.qitaxx')
								}
								
							}
							element.from_id = from_id
							
							element.originalData = originalData

							element.send_time_int = send_time_int
							that.conversationList = that.sortedList(that.conversationList)

						}

					}
				} else if (msg.data.nextReqMessageID) {
					that.getLastMsgNext(conversationID, msg.data.nextReqMessageID, item1)
				}
			})
		},
		// 获取最近联系人
		async getLastChatList() {

			let that = this
			let promise = this.$tim.getConversationList();
			await promise.then(function (imResponse) {
				console.log(imResponse);
				const conversationList = imResponse.data.conversationList; // 缓存中已存在的指定的会话列表

				let positionObj = []
				for (let i = 0; i < conversationList.length; i++) {
					const item = conversationList[i];
					let id = ''
					if (item.userProfile) {
						// console.log("单聊");
						id = item.userProfile.userID.split('_')[1]
						// 匹配用户id 头像 昵称
						that.allList.map(item1 => {
							if (item1.id == id) {
								let content = ""
								let send_time_int = ""
								let type = ""
								let from_id = ""
								let originalData = ""

								that.$tim.getMessageList({ conversationID: item.conversationID }).then(function (msg) {
									// console.log(msg);
									msg.data.messageList.map(msgitem => {
										if(msgitem.isRevoked){
											return
										}
										let data = ""
										if (Crypto.decrypt(msgitem.payload.data, that.smdasdf)) {
											try {
												data = JSON.parse(Crypto.decrypt(msgitem.payload.data, that.smdasdf))
											} catch (error) {
												
											}
										}
										// console.log(data);
										if (data.type == "TxtMsg" && data.content.json.content) {
											content = data.content.json.content
											// console.log(content);
											send_time_int = data.content.json.send_time_int
											type = data.content.json.type
											from_id = data.content.json.from_id

											if (data.content.json.type != 'text'&& data.content.json.type != 'friend_tips') {
												try {
													data.content.json.content = JSON.parse(data.content.json.content)
												} catch (error) {
													
												}
											}
											originalData = data
										}
									})
									if (content != "") {
										item1.from_id = from_id
										item1.originalData = originalData
										item1.send_time_int = send_time_int
										if (type == 'text') {
											item1.content = content
										} else if (type == 'picture'||type == 'emoticon') {
											item1.content = that.$t('chat.tupianxx')
										} else if (type == 'video') {
											item1.content = that.$t('chat.shipinxx')
										} else if (type == 'voice') {
											item1.content = that.$t('chat.yuyinxx')
										} else {
											item1.content = that.$t('chat.qitaxx')
										}
										positionObj.push(item1)
										// console.log(item1);
									} else if (msg.data.nextReqMessageID) {
										that.getLastMsgNext(item.conversationID, msg.data.nextReqMessageID, item1)
										positionObj.push(item1)

									}
									// console.log('_____________________________________________');

								})
							}
						})
					} else if (item.groupProfile) {
						// console.log("群聊");
						id = item.groupProfile.groupID.split('_')[2]
						that.allList.map(item1 => {
							if (item1.id == id) {
								let content = ""
								let send_time_int = ""
								let type = ""
								let fromName = ""
								let from_id = ""
								let originalData = ""
								that.$tim.getMessageList({ conversationID: item.conversationID }).then(function (msg) {
									msg.data.messageList.map(msgitem => {
										if(msgitem.isRevoked){
											return
										}
										let data = ""
										if (Crypto.decrypt(msgitem.payload.data, that.smdasdf)) {
											try {
												data = JSON.parse(Crypto.decrypt(msgitem.payload.data, that.smdasdf))
											} catch (error) {
												
											}
										}
										// console.log(data);
											
										if (data.type == "TxtMsg" && data.content.json.content) {
											content = data.content.json.content
											if (data.content.json.type != 'text'&& data.content.json.type != 'friend_tips') {
												data.content.json.content = JSON.parse(data.content.json.content)
											}
											// console.log(content);
											send_time_int = data.content.json.send_time_int
											type = data.content.json.type
											fromName = data.content.json.fromName
											from_id = data.content.json.from_id
											originalData = data
										}
									})
									if (content != "") {
										

										if (type == 'text') {
											item1.content = fromName + ' : ' + content
										} else if (type == 'at_rich_text') {
											item1.content = fromName + ' : ' + JSON.parse(content).content
										} else if (type == 'picture'||type == 'emoticon') {
											item1.content = fromName + ' : ' + that.$t('chat.tupianxx')
										} else if (type == 'video') {
											item1.content = fromName + ' : ' + that.$t('chat.shipinxx')
										} else if (type == 'voice') {
											item1.content = fromName + ' : ' + that.$t('chat.yuyinxx')
										} else {
											item1.content = fromName + ' : ' + that.$t('chat.qitaxx')
										}
										item1.from_id = from_id
										item1.originalData = originalData
										item1.send_time_int = send_time_int
										positionObj.push(item1)
										// console.log(item1);
									} else if (msg.data.nextReqMessageID) {
										that.getLastMsgNext(item.conversationID, msg.data.nextReqMessageID, item1)
										positionObj.push(item1)

									}

								})


							}
						})
					} else {
						console.log("其他");
						console.log(item);
					}


				}

				// console.log(positionObj);

				that.conversationList = that.sortedList(positionObj)
				// for (let i = 0; i < positionObj.length; i++) {
				// 	const element = positionObj[i];

				// }

				return

				for (let i = 0; i < conversationList.length; i++) {
					const item = conversationList[i];
					let obj = JSON.parse(Crypto.decrypt(item.lastMessage.payload.data, that.smdasdf))
					console.log(obj);

					if (obj.content.action == "asdf" || obj.content.action == "asdf_group" || obj.content.action == "asdf_batch" || obj.content.action == "callback") {

						obj.content.newid = "chat" + i
						positionObj.push(obj)
					}
				}
				console.log(positionObj);
				console.log(that.conversationList);

				that.conversationList = positionObj
				console.log(that.conversationList);

			}).catch(function (imError) {
				console.warn('getConversationList error:', imError); // 获取会话列表失败的相关信息
			});
		},
		init() {
			let tokens = JSON.parse(sessionStorage.getItem("tokens"))
			if (tokens == undefined) {
				// 本地缓存无token userID userSig
				this.getQrCode()
			} else if (tokens) {
				
				var nowDate = new Date().getTime()

				if (nowDate < tokens.expireDate) {
					this.isLogin = true
					this.uid = tokens.im_token.userID.split("_")[1]
					this.token = tokens.token.access_token
					// this.getList()
					this.timLogin(tokens.im_token.userID, tokens.im_token.userSig)
					this.monitor()
				} else if (nowDate >= tokens.expireDate) {
					// 已过期，重新获取
					sessionStorage.removeItem("tokens")
					this.getQrCode()
				}
			}

		},
		// 开启登录二维码状态查询
		starTimer(token) {
			let that = this
			// 3秒轮询结果定时器
			this.timer = setInterval(() => {
				checkQrcode({ token }).then(res => {

					if (res.code == 200) {
						this.loginTpye = res.data.status
						if (res.data.status == 2) {
							// 登录成功
							this.getUserToken()
							this.endTimer()
							setTimeout(() => {
								that.isLogin = true
							}, 2000);
						}
					} else if (res.code == 100010) {
						// 二维码过期
						this.loginTpye = 100010
						this.endTimer()

					}

				})
			}, 3000);
		},
		// 关闭登录二维码状态查询
		endTimer() {
			//清除定时器
			clearInterval(this.timer);
			this.timer = null;
		},
		getQrCode() {
			this.qrcode_url = ''
			this.loginTpye = 0
			getQrcodeUrl().then(res => {
				this.qrcode_url = res.data.qrcode_url
				this.qrcode_token = res.data.token
				this.starTimer(res.data.token)
			})
		},
		openUserMsg() {
			this.showUserMsg = true
		},
		closeUserMsg() {
			this.showUserMsg = false
		},
		closeSeach() {
			this.seaching = false

		},
		// 查看群信息
		openQmsg(group_id) {
			this.chatContentBox = 4
			this.directorySelect = group_id
			if (this.addGroupMembering) {
				return
			}
			let is_hasF = 0
			// console.log(this.selectGroupInfoList.length);
			for (let i = 0; i < this.selectGroupInfoList.length; i++) {
				const element = this.selectGroupInfoList[i];
				// console.log(group_id == element.group_id);
				if (group_id == element.group_id) {
					this.selectGroupInfo = element
					is_hasF = 1
					this.chatContentBox = 3
					break
				}
			}
			if (is_hasF == 0) {
				this.getGroupInfoBtn(group_id,true)
			}
			
		},
		// 查看好友信息
		openPmsg(id) {
			this.chatContentBox = 4
			this.directorySelect = id

			let is_hasF = 0

			for (let i = 0; i < this.selectFinfoList.length; i++) {
				const element = this.selectFinfoList[i];
				if (id == element.uid) {
					this.selectFinfo = element
					is_hasF = 1
					break
				}
			}

			if (is_hasF == 0) {
				this.getFriendInfoBtn(id)
			}
			this.chatContentBox = 2
		},
		startChat(id,type){
			getServerTime().then(res => {
				this.startChatAfter(id,type,res.data.timestamp)
			})
			this.recordLoggerBtn()
		},
		// 开始聊天
		startChatAfter(id,type,time) {
			this.chatContentBox = 4
			// 渲染好友聊天列表
			let is_hasChat = 0
			this.chatType=type
			if (type == 'f') {
				for (let i = 0; i < this.selectFmsgList.length; i++) {
					const element = this.selectFmsgList[i];
					// console.log(element);
					// console.log(id);
					if (id == element.uid) {
						this.selectFmsg = element
						is_hasChat = 1
						break
					}
				}
				if (is_hasChat == 0) {
					// 创建新聊天会话
					let pushitem = {
						uid: id,
						name: this.selectFinfo.nickname,
						data: {
							avatar: this.selectFinfo.avatar,
							content: "",
							from_id: "",
							first: "",
							id: this.selectFinfo.uid,
							name: this.selectFinfo.nickname,
							originalData: '',
							send_time_int: new Date(time),
							type: "f",
						},
						list: [],

					}
					this.selectFmsgList.push(pushitem)
					this.selectFmsg = pushitem
				}

				
			}else if(type == 'g'){
				for (let i = 0; i < this.selectGroupMsgList.length; i++) {
					const element = this.selectGroupMsgList[i];
					if (id == element.uid) {
						this.selectGroupMsg = element
						this.$forceUpdate()
						is_hasChat = 1
						break
					}
				}
				if (is_hasChat == 0) {
					// 创建新聊天会话

					let pushitem = {
						uid: id,
						name: this.selectGroupInfo.groupname,
						data: {
							avatar: this.selectGroupInfo.logo,
							content: "",
							from_id: "",
							first: "",
							id: this.selectGroupInfo.group_id,
							name: this.selectGroupInfo.groupname,
							originalData: '',
							send_time_int: new Date(time),
							type: "g",
						},
						list: [],

					}
					this.selectGroupMsgList.push(pushitem)
					this.selectGroupMsg = pushitem
				}

			}
			
			this.conversationIndex = id
			this.chatContentBox = 1
			// let pushItem = {

			// } 
			// this.conversationList.push(push)

		},
		// 通讯录按字母排序
		getFirstPin(wordArrs) {
			const that = this;
			let newArrs = [];
			for (let i = 0; i < wordArrs.length; i++) {
				//获取每条数据里的名称
				let wordName = wordArrs[i].name;
				// console.log(wordName);
				//获取所有名称的首字母，并且大写
				// let fristName = pinyin(wordName, { style: pinyin.STYLE_NORMAL, heteronym: true })[0][0].substring(0, 1).toUpperCase();
				// console.log(pinyin(wordName, { style: pinyin.STYLE_NORMAL, heteronym: true }));
				let fristName = pinyin.getCamelChars(wordName).split('')[0];

				//进行判断，给原始json数据添加新的键值对
				if (this.lettersArr.includes(fristName)) {
					wordArrs[i].first = fristName;
				} else {
					wordArrs[i].first = "#"
				}
				// 选种状态
				// wordArrs[i].isSelect = false;
				//放入新的数组中
				newArrs.push(wordArrs[i])
			}

			let wordJson = [];
			for (let i = 0; i < that.lettersArr.length; i++) {
				newArrs.forEach((el) => {
					if (that.lettersArr[i] === el.first) {
						wordJson.push(el);
					}
				});
			}
			this.sortChinese(wordJson)

		},
		sortChinese(arr) {
			arr.sort(function (item1, item2) {
				return item1.name.localeCompare(item2.name, 'zh-CN');// localeCompare为string内置函数
			})
			let newArrs = [];

			for (let i = 0; i < this.lettersArr.length; i++) {
				const element = this.lettersArr[i];
				let list = []
				for (let j = 0; j < arr.length; j++) {
					const arrs = arr[j];
					if (arrs.first == element) {
						list.push(arrs)
					}
				}
				if (list.length > 0) {
					newArrs.push({
						first: element,
						list,
					})
				}

			}

			this.directory = newArrs
		},
		seachNavBtn(i) {
			this.seachNavIndex = i
		},
		// 获取禁言信息
		chatToUser(t, e){
			getServerTime().then(res => {
				this.chatToUserAfter(t,e,res.data.timestamp)
			})
		},
		chatToUserAfter(t,e,time) {
			let that = this;
			// console.log(e);
			// console.log(t);
			// console.log(this.selectGoupInfo);

			//用户点击了ctrl+enter触发
			if (t == 'ctrlenter') {
				this.chatText += '\n';
				return;
			} else if (t == 'enter') {
				// console.log('发送消息');
				if (this.chatText == '') {
					Toast(that.$t('chat.xiaoxibuweikong'));
					return
				}
				const timestamp = time*1000;

				if (this.userInfo.no_speak_endtime != 0 && (timestamp<this.userInfo.no_speak_endtime*1000)) {
					Toast(that.$t('chat.zhanghaojinyanz')+this.timestampToTime1(this.userInfo.no_speak_endtime)+that.$t('chat.jiechu'));
					return
				}else{
					this.userInfo.no_speak_endtime = 0
				}
				// 敏感词检测
				this.wordsList.sensitive_words.map( word =>{
					if (this.chatText.indexOf(word) != -1) {
						console.log(word);
						this.dataReportBtn(word)
					}
				})
				// 违禁词检测
				let hasWj = false
				let wordsList = this.chatType == 'f'?this.wordsList.forbidden_words:this.wordsList.group_forbidden_words
				wordsList.map( word =>{
					if (this.chatText.indexOf(word) != -1) {
						hasWj = true
					}
				})
				if (hasWj) {
					Toast(that.$t('chat.minganxiaoxi'));
					return
				}

				if (this.chatType == 'f') {
					// console.log(this.selectFmsg);
					this.adductMsg().then( result => {
						this.sendSay(result, this.prefixF + JSON.stringify(this.selectFmsg.data.id))
					})
				}else if(this.chatType == 'g'){
					// console.log('发送群聊消息');
					console.log(this.selectGroupInfo);
					let dataG = this.selectGroupInfo
					// 群禁言 冻结相关
					if(dataG.can_use != 1){
						// 
						let timestamp = time*1000;

						if (dataG.can_use==2) {
							// Toast(this.selectGroupInfo.no_speak_end_time);
							Toast(this.$t('chat.jinyongzhong'));
						}
						if (dataG.can_use==3) {
							if (dataG.lock_end_time=='永久') {
								Toast(this.$t('chat.yongjiudongjie'));
								return
							}else if (timestamp<dataG.lock_end_time_int*1000) {
								Toast(this.$t('chat.dongjie')+dataG.lock_end_time+this.$t('chat.jiechu'));
								return
							}else{
								this.updateGroupInfoBtn(dataG.group_id)
							}
						}
						if (dataG.can_use==4) {

							if (dataG.no_speak_end_time=='永久'||dataG.no_speak_end_time=='官方检测违规，本群永久封禁') {
								Toast(this.$t('chat.guanfangweigui'));
								return
							}else if (timestamp<dataG.no_speak_end_time_int*1000) {
								Toast(this.$t('chat.jinyan')+dataG.no_speak_end_time+this.$t('chat.jiechu'));
								return
							}else{
								this.updateGroupInfoBtn(dataG.group_id)
							}
						}
					}else if(dataG.user_no_speak !=0){
						// console.log('禁止发言');
						Toast(this.$t('chat.zhanghaojinyanz')+this.timestampToTime1(dataG.user_no_speak)+this.$t('chat.jiechu'));
						return
					}else if(dataG.is_disabled ==1&&dataG.is_root==0){
						// console.log('禁止发言');
						Toast(this.$t('chat.quanyuanjinyan'));
						return
					}
					if (dataG.is_root==0&&dataG.use_manager==1&&dataG.manager_config.can_use==1&&dataG.manager_config.chat_disable_word==1) {
						let hasWj =false
						dataG.manager_config.disable_words.map( word =>{
							if (this.chatText.indexOf(word) != -1) {
								hasWj = true
							}
						})
						if (hasWj) {
							// 有违禁词
							Toast(this.$t('chat.xxsbqunguanji'));
							return
						}
					} 
					this.adductMsgGroup().then( result => {
						this.sendSayGroup(result, this.prefixG + JSON.stringify(this.selectGroupMsg.data.id))
					})

				}
			}
		},
		changeList(i) {
			this.listType = i
			this.seaching = false

		},


		// 从后端获取腾讯im的相关id以及userSig
		userSig() {

		},
		// 登录腾讯im
		timLogin(userID, userSig) {
			let that = this
			//注意，这里的userID必须为String类型
			// let promise = this.$tim.login({ userID: userID.toString(), userSig });
			let promise = this.$tim.login({ userID: userID, userSig });

			promise.then((imResponse) => {
				// 登录成功
				if (imResponse.data.repeatLogin === true) {
					// 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
				}
				console.log('登录成功');
			})
				.catch(function (imError) {
					// sessionStorage.removeItem("tokens")
					// sessionStorage.removeItem("tokens")

					console.warn("login error:", imError); // 登录失败的相关信息
				});
		},
		// 监听im相关数据（自己将所需要监听的数据放到方法中在mounted中调用）
		monitor() {
			let that = this
			// 监听对方发过来的消息（这个监听会时时刻刻监听你正在沟通人的数据，里面是我自己对数据进行了处理，我这边使用的全部是自定义字段 event.data[0].payload.data全是自定义类型）
			let onMessageReceived = (event) => {
				let obj;
				console.log(event);

				let payload = event.data[0].payload;
				console.log(payload);
				var data = JSON.parse(Crypto.decrypt(payload.data, this.smdasdf));
				console.log(data);
				if (data.type == 'TxtMsg') {
					// 单聊
					if (data.content.action == 'asdf') {
						// 聊天窗口更新
						for (let i = 0; i < that.selectFmsgList.length; i++) {
							const element = that.selectFmsgList[i];
							let id = data.content.json.from_id
							if (that.uid == data.content.json.from_id) {
								id = data.content.json.to_id
							}
							if (element.uid == id) {
								if (data.content.json.type == "picture"||data.content.json.type == "video"||data.content.json.type == "voice"||data.content.json.type == "emoticon"||data.content.json.type == "at_rich_text"){
									data.content.json.content=JSON.parse(data.content.json.content)
								}
								console.log(element);
								element.list.push(data)
								break
							}
						}
						// 聊天列表更新
						if (this.selectFmsg.data) {
							if (this.selectFmsg.data.id==data.content.json.from_id) {
								this.hasNew = true
							}
						}
						
						for (let i = 0; i < that.conversationList2.length; i++) {
							const element = that.conversationList2[i];
							console.log(element);
							let id = data.content.json.from_id
							console.log(id);
							if (that.uid == data.content.json.from_id) {
								id = data.content.json.to_id
							}
							console.log(data.content.json.content);
							
							if (id == element.id) {
								if (data.content.json.type == "picture"||data.content.json.type == "video"||data.content.json.type == "voice"||data.content.json.type == "emoticon"||data.content.json.type == "at_rich_text"){
									try {
										data.content.json.content=JSON.parse(data.content.json.content)
									} catch (error) {
										
									}
								}
								console.log(element);
								let selectFmsgID = 0
								if (that.selectFmsg.data) {
									selectFmsgID = that.selectFmsg.data.id
								}

								if (that.uid != data.content.json.from_id&&selectFmsgID != data.content.json.from_id) {
									element.unread++
									this.updateUnread()
								}
								element.originalData = data
								// element.content = data.content.json.content
								let type = data.content.json.type
								if (type == 'text') {
									element.content = data.content.json.content
								} else if (type == 'picture'||type == 'emoticon') {
									element.content = that.$t('chat.tupianxx')
								} else if (type == 'video') {
									element.content = that.$t('chat.shipinxx')
								} else if (type == 'voice') {
									element.content = that.$t('chat.yuyinxx')
								} else {
									element.content = that.$t('chat.qitaxx')
								}

								element.send_time_int = data.content.json.send_time_int
								that.conversationList2.unshift(that.conversationList2.splice(i, 1)[0])
								that.$forceUpdate()
								break
							} else if (i == that.conversationList2.length - 1) {
								if (data.content.json.type == "picture"||data.content.json.type == "video"||data.content.json.type == "voice"||data.content.json.type == "emoticon"||data.content.json.type == "at_rich_text"){
									try {
										data.content.json.content=JSON.parse(data.content.json.content)
									} catch (error) {
										
									}
								}
								let type = data.content.json.type
								let content = data.content.json.content
								if (type == 'text') {
									content = data.content.json.content
								} else if (type == 'picture'||type == 'emoticon') {
									content = that.$t('chat.tupianxx')
								} else if (type == 'video') {
									content = that.$t('chat.shipinxx')
								} else if (type == 'voice') {
									content = that.$t('chat.yuyinxx')
								} else {
									content = that.$t('chat.qitaxx')
								}
								let userinfo = data.content.json

								that.conversationList2.unshift({
									avatar: userinfo.fromLogo,
									content: content,
									from_id: userinfo.from_id,
									id: userinfo.from_id,
									name: userinfo.fromName,
									originalData: data,
									send_time_int: userinfo.send_time_int,
									type: 'f',
									unread: 1,

								})
								this.updateUnread()
								break
							}
						}


					} else if (data.content.action == 'asdf_group') {
						// 聊天窗口更新
						for (let i = 0; i < that.selectGroupMsgList.length; i++) {
							const element = that.selectGroupMsgList[i];
							let id = data.content.json.to_gid
							// console.log(id);
							// console.log(element);
							

							if (element.uid == id) {
								if (data.content.json.type == "picture"||data.content.json.type == "video"||data.content.json.type == "voice"||data.content.json.type == "emoticon"||data.content.json.type == "at_rich_text"){
									data.content.json.content=JSON.parse(data.content.json.content)
									console.log(data.content.json.content);
								}
								element.list.push(data)
								
								// 聊天状态按钮
								if (data.content.json.type == 'at_rich_text') {
									data.content.json.content.at_user.forEach(item => {
										if (that.uid == item.uid ) {
											element.chatBoxExTips=1
										}
									});
								}
							}
							
						}
						// 聊天列表更新
						if (this.selectGroupMsg.data) {
							if (this.selectGroupMsg.data.id==data.content.json.to_gid) {
								this.hasNew = true
							}
						}
						
						for (let i = 0; i < that.conversationList2.length; i++) {
							const element = that.conversationList2[i];
							let id = data.content.json.to_gid
							let exTips = 0
							if (id == element.id) {
								if (data.content.json.type == "picture"||data.content.json.type == "video"||data.content.json.type == "voice"||data.content.json.type == "emoticon"||data.content.json.type == "at_rich_text"){
									try {
										data.content.json.content=JSON.parse(data.content.json.content)
									} catch (error) {
										
									}
								}
								console.log(element);
								let selectGroupMsgID = 0
								if (that.selectGroupMsg.data) {
								console.log(that.selectGroupMsg);

									selectGroupMsgID = that.selectGroupMsg.data.id
								}
								if (that.uid != data.content.json.from_id&& selectGroupMsgID!= data.content.json.to_gid) {
									element.unread++
									this.updateUnread()
								}
								element.originalData = data
								// element.content = data.content.json.content
								let type = data.content.json.type
								let fromName = data.content.json.fromName 
								if (type == 'text') {
									element.content = fromName + ' : ' + data.content.json.content
								} else if (type == 'at_rich_text') {
									data.content.json.content.at_user.forEach(item => {
										if (that.uid == item.uid ) {
											element.exTips=1
										}
									});
									element.content = fromName + ' : ' + data.content.json.content.content 

								} else if (type == 'picture'||type == 'emoticon') {
									element.content = fromName + ' : ' + that.$t('chat.tupianxx')
								} else if (type == 'video') {
									element.content = fromName + ' : ' + that.$t('chat.shipinxx')
								} else if (type == 'voice') {
									element.content = fromName + ' : ' + that.$t('chat.yuyinxx')
								} else {
									element.content = that.$t('chat.qitaxx')
								}
								element.send_time_int = data.content.json.send_time_int
								that.conversationList2.unshift(that.conversationList2.splice(i, 1)[0])
								console.log(element);
								that.$forceUpdate()
								break
							} else if (i == that.conversationList2.length - 1) {
								if (data.content.json.type == "picture"||data.content.json.type == "video"||data.content.json.type == "voice"||data.content.json.type == "emoticon"||data.content.json.type == "at_rich_text"){
									data.content.json.content=JSON.parse(data.content.json.content)
								}
								let type = data.content.json.type
								let content = data.content.json.content
								let fromName = data.content.json.fromName 
								console.log(data.content.json);
								if (type == 'text') {
									content = fromName + ' : ' + data.content.json.content
								} else if (type == 'at_rich_text') {
									content.at_user.forEach(element => {
										if (that.uid == element.uid ) {
											exTips=1
										}
									});
									content = fromName + ' : ' + data.content.json.content.content 
								} else if (type == 'picture'||type == 'emoticon') {
									content = fromName + ' : ' + that.$t('chat.tupianxx')
								} else if (type == 'video') {
									content = fromName + ' : ' + that.$t('chat.shipinxx')
								} else if (type == 'voice') {
									content = fromName + ' : ' + that.$t('chat.yuyinxx')
								} else {
									content = that.$t('chat.qitaxx')
								}
								let userinfo = data.content.json

								that.conversationList2.unshift({
									avatar: userinfo.toLogo,
									content: content,
									from_id: userinfo.from_id,
									id: userinfo.to_gid,
									name: userinfo.toName,
									originalData: data,
									send_time_int: userinfo.send_time_int,
									type: 'g',
									unread: 1,
									exTips,  // 额外提示  0无提示 1@消息 2群公告 3群喇叭
								})
								this.updateUnread()
								break
							}
							
						}
						


					} else if (data.content.action == 'callback' ) {
						if (data.content.info == '赠送卡券') {
							// app端发送卡片收到的回调
							// 聊天窗口更新
							for (let i = 0; i < that.selectFmsgList.length; i++) {
								const element = that.selectFmsgList[i];

								let id = data.content.json.data.toid
								if (element.uid == id) {
									let newData = {
										type:"TxtMsg",
										content:{
											action: "asdf",
											info: "赠送卡券",
											json:	data.content.json.data.data
										}
									}
									element.list.push(newData)
									break
								}
							}
							// 聊天列表更新
							this.hasNew = true
							for (let i = 0; i < that.conversationList2.length; i++) {
								const element = that.conversationList2[i];
								let id = data.content.json.data.toid

								if (id == element.id) {
									console.log(element);
									let newData = {
										type:"TxtMsg",
										content:{
											action: "asdf",
											info: "赠送卡券",
											json: data.content.json.data.data
										}
									}
									element.originalData = newData
									// element.content = data.content.json.content
									element.content = that.$t('chat.yuyinxx')

									element.send_time_int = data.content.json.data.data.send_time_int
									that.conversationList2.unshift(that.conversationList2.splice(i, 1)[0])
									that.$forceUpdate()
									break
								} else if (i == that.conversationList2.length - 1) {
									
									let content = that.$t('chat.yuyinxx')
									let newData = {
										type:"TxtMsg",
										content:{
											action: "asdf",
											info: "赠送卡券",
											json: data.content.json.data.data
										}
									}

									let userinfo = data.content.json.data.data

									that.conversationList2.unshift({
										avatar: userinfo.fromLogo,
										content: content,
										from_id: userinfo.from_id,
										id: userinfo.from_id,
										name: userinfo.fromName,
										originalData: newData,
										send_time_int: userinfo.send_time_int,
										type: 'f',
										unread: 0,

									})
									break
								}
							}
						}else if(data.content.info == '群成员禁言通知'||data.content.info == '加入群聊系统消息'||data.content.info == '群成员离群通知'||data.content.info == '群公告变更通知'||data.content.info == '群喇叭通知'){
							// 聊天窗口更新
							data.content.json.data.data.content = JSON.parse(data.content.json.data.data.content)
							for (let i = 0; i < that.selectGroupMsgList.length; i++) {
								const element = that.selectGroupMsgList[i];
								let id = data.content.json.data.data.to_gid
								console.log(id);
								console.log(element);

								if (element.uid == id) {

									console.log(element);
									// console.log(data.content.json);
									let newData = {
										type:"TxtMsg",
										content:{
											action: "asdf_group",
											info: data.content.info,
											json:	data.content.json.data.data
										}
									}
									element.list.push(newData)
									break
								}
							}
							// 聊天列表更新
							this.hasNew = true
							for (let i = 0; i < that.conversationList2.length; i++) {
								const element = that.conversationList2[i];
								let id = data.content.json.data.data.to_gid
								
								if (id == element.id) {
									console.log(element);
									let newData = {
										type:"TxtMsg",
										content:{
											action: "asdf_group",
											info: data.content.info,
											json: data.content.json.data.data
										}
									}
									element.originalData = newData
									// element.content = data.content.json.content
									element.content = "["+data.content.info+"]"
									if (data.content.info == '群公告变更通知') {
										element.exTips=2
									}else if (data.content.info == '群喇叭通知') {
										element.exTips=3
										if (this.selectGroupInfo.group_id==data.content.json.data.data.to_gid) {
											console.log(data.content.json.data.data);
											this.showQlaba = true
											this.showQlabaData={
												fromLogo:data.content.json.data.data.fromLogo,
												fromName:data.content.json.data.data.fromName,
												content:data.content.json.data.data.content,
											}
											setTimeout(() => {
												this.showQlaba = false
											}, 5000);
										}
									}
									element.send_time_int = data.content.json.data.data.send_time_int
									that.conversationList2.unshift(that.conversationList2.splice(i, 1)[0])
									that.$forceUpdate()
									break
								} else if (i == that.conversationList2.length - 1) {
									
									let content = "["+data.content.info+"]"
									let newData = {
										type:"TxtMsg",
										content:{
											action: "asdf_group",
											info: data.content.info,
											json: data.content.json.data.data
										}
									}

									let userinfo = data.content.json.data.data
									let exTips = 0
									if (data.content.info == '群公告变更通知') {
										exTips=2
									}else if (data.content.info == '群喇叭通知') {
										exTips=3
										if (this.selectGroupInfo.group_id==data.content.json.data.data.to_gid) {
											console.log(data.content.json.data.data);
											this.showQlaba = true
											this.showQlabaData={
												fromLogo:data.content.json.data.data.fromLogo,
												fromName:data.content.json.data.data.fromName,
												content:data.content.json.data.data.content,
											}
											setTimeout(() => {
												this.showQlaba = false
											}, 5000);
										}
									}
									that.conversationList2.unshift({
										avatar: userinfo.toLogo,
										content: content,
										from_id: userinfo.from_id,
										id: userinfo.to_gid,
										name: userinfo.toName,
										originalData: newData,
										send_time_int: userinfo.send_time_int,
										type: 'g',
										unread: 0,
										exTips
									})
									break
								}
							}
							// 群信息更新
							that.updateGroupInfoBtn(data.content.json.data.data.to_gid)
						}
						

					} else if (data.content.action == 'data_handle') {
						// 收到撤回指令
						that.deleteMessageBtn(data.content.json.msg_ids,data.content.json)
					} else if (data.content.action == 'data_handle_group') {
						// 收到撤回指令 群
						that.deleteMessageBtn_group(data.content.json.msg_ids,data.content.json)
					} else if (data.content.action == 'group_cmd') {
						// 群状态变更指令
						let json = data.content.json
						console.log(json);
						this.updateGroupInfoBtn(json.cmd_data.group_id)
						
					}
					if (this.isGoBottom) {
						setTimeout(() => {
							this.$nextTick(() => {
								var container = this.$el.querySelector('#chatMessageBox');
								if (container) {
									container.scrollTop = container.scrollHeight;
								}
							})
						}, 100);
					}
					
				}
				// console.log(Crypto.decrypt(payload.data, this.smdasdf));
				return

				if (event.data[0].from !== this.chartId) return;
				if (payload.data == "text") {
					obj = {
						mineMsg: false,
						contactText: payload.description,
						read: false,
						id: event.data[0].ID,
						time: event.data[0].clientTime,
						avatar: event.data[0].avatar,
					};
				} else if (payload.data == "cv") {
					obj = {
						cv: true,
						contactText: payload.description,
						id: event.data[0].ID,
						time: event.data[0].clientTime,
						avatar: event.data[0].avatar,
					};
				} else if (payload.data == "connect") {
					obj = {
						connect: true,
						contactText: payload.description,
						phoneShow: true,
						id: event.data[0].ID,
						time: event.data[0].clientTime,
						avatar: event.data[0].avatar,
					};
				} else if (payload.data == "img") {
					obj = {
						img: true,
						contactText: payload.description,
						id: event.data[0].ID,
						time: event.data[0].clientTime,
						avatar: event.data[0].avatar,
					};
				} else if (payload.data == "inte") {
					obj = {
						invite: true,
						contactText: JSON.parse(item.payload.description),
						id: event.data[0].ID,
						time: event.data[0].clientTime,
						avatar: event.data[0].avatar,
					};
				}
				this.wordList.push(obj);
				this.$nextTick(() => {
					if (this.destory && this.chartId) {
						//这里是我处理聊天框页面的滚动，只要有最新的数据过来就滚动条滚动到最底部
						this.scrollEvent();
					}
				});
			};
			// 监听对方发过来的消息
			this.$tim.on(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);



			let conversationList = (event) => {
				this.chartList = event.data;
			};
			// 监听会话列表
			this.$tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, conversationList);

			// 监听互踢
			this.$tim.on(TIM.EVENT.SDK_NOT_READY, this.onSdkNotReady);

			// 监听消息的已读,如果已读回执之后会进入
			let onMessageReadByPeer = (event) => {
				this.wordList.forEach((item) => {
					if (item.mineMsg) {
						item.read = true;
					}
				});
			};
			// this.$tim.on(TIM.EVENT.MESSAGE_READ_BY_PEER, onMessageReadByPeer);
			// SDK准备完成 调最近聊天列表
			this.$tim.on(TIM.EVENT.SDK_READY, this.getList);
		},
		onSdkNotReady(){
			this.logoutBtn()
		},
		// 调取会话列表
		readyList() {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)",
			});
			// 拉取会话列表
			let promise = this.$tim.getConversationList();
			promise
				.then((imResponse) => {
					let conversationList = imResponse.data.conversationList;
					// console.log("拉取的会话列表", conversationList);
					//这里有你所要的会话列表数据
					this.chartList = conversationList;
					//因为我这里需要特殊处理一下数据，所以进行了数据处理并赋值给一个新的数组（你用不到可以不用管）
					let positionObj = conversationList.map((item) => {
						if (item.lastMessage.payload.extension) {
							if (JSON.parse(item.lastMessage.payload.extension).id) {
								return {
									position_id: JSON.parse(item.lastMessage.payload.extension).jopId,
									user_id: JSON.parse(item.lastMessage.payload.extension).id,
									im_user_id: item.userProfile.userID,
								};
							}
						}
					});
					// console.log(positionObj)
					let positionObjOne = positionObj.filter((item) => {
						return item;
					});
					this.setConnect({ positions: positionObjOne });
					loading.close();
				})
				.catch((imError) => {
					console.log(imError);
					loading.close();
				});
		},
		// 点击联系人进行聊天
		userClick(id, extension) {
			// 判断是否包含扩展字段
			if (extension) {
				let extensionOne = JSON.parse(extension);
				this.posiDetail(extensionOne.jopId);
				this.extension = extensionOne;
			}
			this.chartId = id;
			this.scrollEvent();
			this.concatShow = true;
			this.getSayList({ conversationID: `C2C${id}`, count: 15 });
			// 将某会话下所有未读消息已读上报
			let promise = this.$tim.setMessageRead({ conversationID: `C2C${id}` });
			promise
				.then(function (imResponse) {
					// 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
				})
				.catch(function (imError) {
					// 已读上报失败
					console.warn("setMessageRead error:", imError);
				});
		},
		// 拉取某个人的会话数据
		//options里面的内容为{ conversationID: `C2C${id}`, count: 15 }
		getSayList(options) {
			// 打开某个会话时，第一次拉取消息列表，注意！第一次拉取时不要传入 nextReqMessageID
			let promise = this.$tim.getMessageList(options);
			promise.then((imResponse) => {
				let messageList = imResponse.data.messageList; // 消息列表。
				// console.log("聊天列表", messageList);
				// 获取最后一个聊天内容并将扩展字段赋值给data中的extension
				if (messageList[messageList.length - 1].payload.extension) {
					this.extension = JSON.parse(
						messageList[messageList.length - 1].payload.extension
					);
				}
				//在这里进行数据处理item.payload.data全是自己自定义的聊天类型
				let wordList = messageList.map((item) => {
					if (item.from == this.userId && item.payload.data == "text") {
						return {
							mineMsg: true,
							contactText: item.payload.description,
							read: item.isPeerRead ? true : false,
							id: item.ID,
							time: item.clientTime,
							avatar: item.avatar,
						};
					} else if (item.payload.data == "cv") {
						return {
							cv: true,
							contactText: item.payload.description,
							id: item.ID,
							time: item.clientTime,
							avatar: item.avatar,
						};
					} else if (item.from == this.userId && item.payload.data == "connect") {
						return {
							connect: true,
							contactText: item.payload.description,
							phoneShow: false,
							id: item.ID,
							time: item.clientTime,
							avatar: item.avatar,
						};
					} else if (item.from !== this.userId && item.payload.data == "text") {
						return {
							mineMsg: false,
							contactText: item.payload.description,
							read: false,
							id: item.ID,
							time: item.clientTime,
							avatar: item.avatar,
						};
					} else if (item.from !== this.userId && item.payload.data == "connect") {
						return {
							connect: true,
							contactText: item.payload.description,
							phoneShow: true,
							id: item.ID,
							time: item.clientTime,
							avatar: item.avatar,
						};
					} else if (item.payload.data == "inte") {
						return {
							invite: true,
							contactText: JSON.parse(item.payload.description),
							id: item.ID,
							time: item.clientTime,
							avatar: item.avatar,
						};
					} else if (item.payload.data == "cvText") {
						return {
							mineMsg: true,
							contactText: "附件简历已发出",
							read: item.isPeerRead ? true : false,
							time: item.clientTime,
							id: item.ID,
							avatar: item.avatar,
						};
					}
				});
				let wordListOne = wordList.filter((item) => {
					return item;
				});

				//从这里往下是处理对话框滚动条的js
				if (this.concatShow) {
					this.wordList = wordListOne;
					this.scrollEvent();
				} else {
					for (let i = wordListOne.length - 1; i >= 0; i--) {
						this.wordList.unshift(wordListOne[i]);
					}
					this.$nextTick(() => {
						let msg = document.getElementsByClassName("chart-rightr-item")[0]; // 获取对象
						msg.scrollTop = msg.scrollHeight - this.scrollHeight; // 滚动高度
					});
				}
				// console.log(this.wordList);
				//这个地方是用来处理获取上一页内容的数据
				this.nextReqMessageID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
				this.isCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。isCompleted 为 true 时，nextReqMessageID 为 ""。
			});
		},


		// im发送自定义消息  payload--->对应下面方法中的参数传递进来
		sendSay(data, chartId,isNeedParse) {
			let that = this
			let ext = {
				entity: {
					data: {
						from_id: this.userInfo.uid,
						is_friend: 0,
						logo: this.userInfo.avatar,
						nick_name: this.userInfo.nickname,
					},
					msg_content: "您收到一条事密达加密消息",
					type: "friend_record" 
				}
			}
			console.log(data);
			ext.entity.data = JSON.stringify(ext.entity.data)
			ext.entity = JSON.stringify(ext.entity)
			ext = JSON.stringify(ext)
			// 发送文本消息，Web 端与小程序端相同
			// 1. 创建消息实例，接口返回的实例可以上屏
			let message = this.$tim.createCustomMessage({
				to: chartId,
				conversationType: TIM.TYPES.CONV_C2C,
				payload: {
					data: Crypto.encrypt(JSON.stringify(data), this.smdasdf), // 内容
					description: '', // 
				}
			});
			
			// 2. 发送消息
			let promise = this.$tim.sendMessage(message, {
				offlinePushInfo: {
					title: this.userInfo.nickname, // 离线推送标题
					description: "您收到一条事密达加密消息", // 离线推送内容
					// androidOPPOChannelID: '' // 离线推送设置 OPPO 手机 8.0 系统及以上的渠道 ID
					extension: ext//离线推送透传内容
				}
			});
			promise
				.then((imResponse) => {
					// console.log("发送消息", imResponse);
					
					console.log(imResponse);
					// 将某会话下所有未读消息已读上报
					let promiseOne = this.$tim.setMessageRead({
						conversationID: imResponse.data.message.conversationID,
					});
					// promiseOne
					// 	.then(function (imResponse) {
					// 		// 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
					// 	})
					// 	.catch(function (imError) {
					// 		// 已读上报失败
					// 		console.warn("setMessageRead error:", imError);
					// 	});
					// 发送成功
					// 聊天窗口更新
					for (let i = 0; i < that.selectFmsgList.length; i++) {
						const element = that.selectFmsgList[i];
						// console.log(element);
						if (that.selectFmsg.data.id == element.uid) {
							let item = JSON.parse(Crypto.decrypt(imResponse.data.message.payload.data, that.smdasdf))
							

							if (isNeedParse==1) {
								item.content.json.content=JSON.parse(item.content.json.content)
							}
							element.list.push(item)
							break
						}
					}
					
					// 聊天列表更新
					for (let i = 0; i < that.conversationList2.length; i++) {
						const element = that.conversationList2[i];
						if (that.selectFmsg.data.id == element.id) {
							element.content = data.content.json.content
							element.send_time_int = data.content.json.send_time_int
							element.originalData = data
							if(isNeedParse==1){
								if (JSON.parse(data.content.json.content).type == 'picture'||JSON.parse(data.content.json.content).type == 'emoticon') {
									element.content = that.$t('chat.tupianxx')
								} else if (JSON.parse(data.content.json.content).type == 'video') {
									element.content = that.$t('chat.shipinxx')
								} else if (JSON.parse(data.content.json.content).type == 'voice') {
									element.content = that.$t('chat.yuyinxx')
								}
							}
							element.unread = 0
							that.conversationList2.unshift(that.conversationList2.splice(i, 1)[0])
							that.$forceUpdate()
							break
						} else if (i == that.conversationList2.length - 1) {
							console.log(element);
							let userinfo = data.content.json
							console.log(userinfo);
							if(isNeedParse==1){
								if (JSON.parse(data.content.json.content).type == 'picture'||JSON.parse(data.content.json.content).type == 'emoticon') {
									element.content = that.$t('chat.tupianxx')
								} else if (JSON.parse(data.content.json.content).type == 'video') {
									element.content = that.$t('chat.shipinxx')
								} else if (JSON.parse(data.content.json.content).type == 'voice') {
									element.content = that.$t('chat.yuyinxx')
								}
							}
							that.conversationList2.unshift({
								avatar: userinfo.toLogo,
								content: userinfo.content,
								from_id: userinfo.from_id,
								id: userinfo.to_id,
								name: userinfo.toName,
								originalData: data,
								send_time_int: userinfo.send_time_int,
								type: that.selectFmsg.data.type,
								unread: 0,
							})
							break
						}
					}
					that.chatText = ""
					setTimeout(() => {
						this.$nextTick(() => {
							var container = this.$el.querySelector('#chatMessageBox');
							container.scrollTop = container.scrollHeight;
						})
					}, 100);
					this.seaching = false
					this.listType = 1
					this.updateUnread()
					console.log(that.conversationList2);

					return
					//
					let obj;
					let payload = imResponse.data.message.payload;
					let message = imResponse.data.message;
					if (payload.data == "text") {
						obj = {
							mineMsg: true,
							contactText: payload.description,
							read: false,
							time: message.clientTime,
							id: message.ID,
							avatar: message.avatar,
						};
					} else if (payload.data == "cv") {
						obj = {
							cv: true,
							contactText: payload.description,
							time: message.clientTime,
							id: message.ID,
							avatar: message.avatar,
						};
					} else if (payload.data == "connect") {
						obj = {
							connect: true,
							contactText: payload.description,
							time: message.clientTime,
							phoneShow: false,
							id: message.ID,
							avatar: message.avatar,
						};
					} else if (payload.data == "cvText") {
						obj = {
							mineMsg: true,
							contactText: "附件简历已发出",
							read: false,
							time: message.clientTime,
							id: message.ID,
							avatar: message.avatar,
						};
					}
					this.wordList.push(obj);
					this.scrollEvent();
				})
				.catch(function (imError) {
					// 发送失败
					Toast('消息发送失败');
					console.warn("消息发送失败:", imError);
				});
		},
		sendSayGroup(data, chartId,isNeedParse) {
			let that = this
			let ext = {
				entity: {
					data: {
						from_id: this.userInfo.uid,
						is_friend: 0,
						logo: this.userInfo.avatar,
						nick_name: this.userInfo.nickname,
					},
					msg_content: "您收到一条事密达加密消息",
					type: "group_record",
				}
			}
			ext.entity.data = JSON.stringify(ext.entity.data)
			ext.entity = JSON.stringify(ext.entity)
			ext = JSON.stringify(ext)
			// 发送文本消息，Web 端与小程序端相同
			// 1. 创建消息实例，接口返回的实例可以上屏
			let message = this.$tim.createCustomMessage({
				to: chartId,
				conversationType: TIM.TYPES.CONV_GROUP,
				payload: {
					data: Crypto.encrypt(JSON.stringify(data), this.smdasdf), // 内容
					description: '', // 
				}
			});
			// 2. 发送消息
			let promise = this.$tim.sendMessage(message, {
				offlinePushInfo: {
					title: this.userInfo.nickname, // 离线推送标题
					description: "您收到一条事密达加密消息", // 离线推送内容
					// androidOPPOChannelID: '' // 离线推送设置 OPPO 手机 8.0 系统及以上的渠道 ID
					extension: ext//离线推送透传内容
				}
			});
			promise
				.then((imResponse) => {
					// console.log("发送消息", imResponse);

					// console.log(imResponse);
					// 将某会话下所有未读消息已读上报
					let promiseOne = this.$tim.setMessageRead({
						conversationID: imResponse.data.message.conversationID,
					});
					// promiseOne
					// 	.then(function (imResponse) {
					// 		// 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
					// 	})
					// 	.catch(function (imError) {
					// 		// 已读上报失败
					// 		console.warn("setMessageRead error:", imError);
					// 	});
					// 发送成功
					// 聊天窗口更新
					for (let i = 0; i < that.selectGroupMsgList.length; i++) {
						const element = that.selectGroupMsgList[i];
						// console.log(element);
						// console.log(id);

						if (that.selectGroupMsg.data.id == element.uid) {
							let item = JSON.parse(Crypto.decrypt(imResponse.data.message.payload.data, that.smdasdf))
							if (isNeedParse==1) {
								item.content.json.content=JSON.parse(item.content.json.content)
							}
							element.list.push(item)
							break
						}
					}
					// 聊天列表更新
					for (let i = 0; i < that.conversationList2.length; i++) {
						const element = that.conversationList2[i];
						if (that.selectGroupMsg.data.id == element.id) {
							element.content = data.content.json.content
							element.send_time_int = data.content.json.send_time_int
							element.originalData = data
							if(isNeedParse==1){
								if (JSON.parse(data.content.json.content).type == 'picture'||JSON.parse(data.content.json.content).type == 'emoticon') {
									element.content = that.$t('chat.tupianxx')
								} else if (JSON.parse(data.content.json.content).type == 'video') {
									element.content = that.$t('chat.shipinxx')
								} else if (JSON.parse(data.content.json.content).type == 'voice') {
									element.content = that.$t('chat.yuyinxx')
								}
							}
							element.unread = 0
							that.conversationList2.unshift(that.conversationList2.splice(i, 1)[0])
							that.$forceUpdate()
							break
						} else if (i == that.conversationList2.length - 1) {

							console.log(element);
							let userinfo = data.content.json
							console.log(userinfo);
							if(isNeedParse==1){
								if (JSON.parse(data.content.json.content).type == 'picture'||JSON.parse(data.content.json.content).type == 'emoticon') {
									element.content = that.$t('chat.tupianxx')
								} else if (JSON.parse(data.content.json.content).type == 'video') {
									element.content = that.$t('chat.shipinxx')
								} else if (JSON.parse(data.content.json.content).type == 'voice') {
									element.content = that.$t('chat.yuyinxx')
								}
							}
							that.conversationList2.unshift({
								avatar: userinfo.toLogo,
								content: element.content,
								from_id: userinfo.from_id,
								id: userinfo.to_gid,
								name: userinfo.toName,
								originalData: data,
								send_time_int: userinfo.send_time_int,
								type: that.selectGroupMsg.data.type,
								unread: 0,
							})
							break
						}
					}
					that.chatText = ""
					
					setTimeout(() => {
						this.$nextTick(() => {
							var container = this.$el.querySelector('#chatMessageBox');
							container.scrollTop = container.scrollHeight;
						})
					}, 100);
					// console.log(that.conversationList2);
					this.seaching = false
					this.listType = 1
					this.updateUnread()
					return
					
				})
				.catch(function (imError) {
					// 发送失败
					Toast(that.$t('chat.xiaoxifasongshibai'));
					console.warn("sendMessage error:", imError);
				});
		},
		//其中一个发送消息的方法
		cvClickOne() {
			this.sendSay({
				data: "connect",  //自定义字段，获取消息列表以及会话列表根据这个进行判断展示
				description: "已拒绝发送简历",//消息内容
				extension: JSON.stringify(this.extension),//其他自定义字段类型，必须是字符串，可根据自己项目需求进行填写
			});
			//调用滚动条滚动到底部的方法
			this.scrollEvent();
		},
		// 控制滚动条滚动到底部
		scrollEvent() {
			// 自动滚动到底部
			this.$nextTick(() => {
				let msg = document.getElementsByClassName("chart-rightr-item")[0]; // 获取对象
				msg.scrollTop = msg.scrollHeight + 10; // 滚动高度
			});
		},
		// 监听滚动条的高度，滚动到顶部后拉取聊天数据
		scrollEventSay(e) {
			if (!this.chartId) return;
			this.scrollHeight = e.srcElement.scrollHeight;
			if (e.srcElement.scrollTop == 0 && !this.isCompleted) {
				this.concatShow = false;
				this.getSayList({
					conversationID: `C2C${this.chartId}`,
					//拉取上一页聊天数据必传nextReqMessageID，在拉取会话列表中有提到
					nextReqMessageID: this.nextReqMessageID,
					count: 15,
				});
			}
		},
	},

}
</script> 
<style scoped lang="less">
@font-face {
	font-family: D-DIN-Bold;
	src: url('@/static/fonts/D-DIN-Bold.otf');
	font-weight: normal;
	font-style: normal;
}

.text {
	font-family: 'Cormorant';
	font-size: 24px;
}

.main {
	width: 100vw;
	height: 100vh;
}

.pageItem {
	/* background-attachment:fixed; */
	// background-color: #3296FA;
	background-color: rgb(226, 237, 254);
	background-position: top;
	background-repeat: no-repeat;
	background-size: auto 100vh;
	width: 100vw;
	// min-height: 100vh;
	height: 100vh;
	/* height: 1800px; */
	position: relative;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
}


.contentBox {
	width: 1164px;
	height: 720px;
	background: #FFFFFF;
	border-radius: 32px;
	// position: absolute;
	// left: 50%;
	// top: 48%;
	// transform: translate(-50%, -50%);
	// transform: scale(.7);
}

.contentBox2 {
	height: 704px !important;
	width: 1001px !important;
}


.unLoginBox {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

}

.unLogintitle {
	width: 1164px;
	height: 48px;
	background: #ECF5FF;
	border-radius: 32px 32px 0px 0px;
	font-size: 20px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 600;
	color: #01071A;
	line-height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.unLoginCodeBox {
	width: 204px;
	height: 204px;
	background-position: top;
	background-repeat: no-repeat;
	background-size: 100%;
	margin-top: 72px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.unLoginCode {
	width: 190px;
	height: 190px;
}
.unLoginCode1{
	width: 175px;
	height: 175px;

}
.loginOK,
.qrcodeErr {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	align-items: center;
	font-size: 16px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 600;
	color: #01071A;
	line-height: 22px;
	white-space: nowrap;
}

.qrcodeErr {
	cursor: pointer;
}

.loginOKImg {
	width: 24px;
	height: 24px;
	margin-right: 8px;
}

.unLoginSubTitle {
	font-size: 20px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #01071A;
	line-height: 28px;
	margin-top: 20px;
}

.unLoginTips {
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #8F8F96;
	line-height: 22px;
	margin-top: 72px;
	text-align:left;
}

.unLoginRow {
	width: 561px;
	margin-bottom: 16px;
}

.chatBox {
	width: 100%;
	height: 100%;
	display: flex;
	position: relative;
}

.chatNav {
	width: 64px;
	height: 704px;
	background: #E9EAF1;
	border-radius: 20px 0px 0px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.userImgBox {
	width: 40px;
	height: 40px;
	border-radius: 100px;
	overflow: hidden;
	margin-top: 20px;
	margin-bottom: 24px;
	cursor: pointer;
}

.userImg {
	width: 40px;
	height: 40px;
}

.listBtn {
	width: 44px;
	height: 44px;
	border-radius: 9px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 8px;
	position: relative;
}
.listBtn:hover {
	background: #DADBDC;
}
.unreadCount{
	font-size: 12px;
    font-family: D-DIN-Bold, D-DIN;
    font-weight: bold;
    color: #FFFFFF;
    padding: 2px 5px;
    background: #FA3232;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 8px;
	position: absolute;
	// right: 0;
	left: 30px;
	transform: translateX(-50%);
	top: 4px;
	z-index: 10;
}

.listBtn_A {
	background: #DADBDC;
}

.listBtnIcon {
	width: 24px;
	height: 24px;
	cursor: pointer;
}

.chatListBox {
	width: 347px;
	height: 704px;
	background: #FFFFFE;
	display: flex;
	flex-direction: column;
	align-items: center;
}

// 搜索列表
.seachList {
	margin-top: 16px;
	height: 632px;
	width: 347px;
	overflow-y: hidden;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.seachNav {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
	border-bottom: 1px solid #EBEBEB;

}

.seachNavBtn {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;

	color: #01071A;
	line-height: 20px;
	padding: 0 0 8px;
	cursor: pointer;
}

.seachNavBtn_A {
	color: #3486FF;
	position: relative;
}

.seachNavBtn_A::after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 13px;
	height: 2px;
	background: #3486FF;
	border-radius: 2px;
}

.seachListBox {
	width: 347px;
	height: 704px;
	overflow-y: scroll;

}

.seachListC {
	width: 100%;
	box-sizing: border-box;
	padding: 16px 12px 0;

	.seachListCTop {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.seachListCTopL {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #8F8F96;
			line-height: 20px;
		}

		.seachListCTopR {
			font-size: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #8F8F96;
			line-height: 17px;
			cursor: pointer;
		}

		.seachListMore {
			width: 6px;
			height: 10px;
		}
	}


}

.seachListCBottom {
	width: 100%;
	box-sizing: border-box;
	padding-top: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.seachListRow {
		width: 323px;
		height: 81px;
		padding-left: 16px;
		display: flex;
		align-items: center;
		box-sizing: border-box;
		cursor: pointer;

		.seachListAvatar {
			width: 44px;
			height: 44px;
			border-radius: 60px;
		}

		.seachListName {
			margin-left: 8px;
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #01071A;
			line-height: 22px;
			white-space: nowrap;
			overflow: hidden;
			max-width: 200px;
			text-overflow: ellipsis;
		}
	}

}

.noSeach {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	.noSeachImg {
		width: 182px;
		height: 182px;
		margin: 103px 0 7px;
	}

	.noSeachTips {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #01071A;
		line-height: 20px;
	}
}
.loadingBox{
	display: flex;
    align-items: center;
    justify-content: center;
}

// 通讯录列表
.directoryList,.loadingBox {
	margin-top: 16px;
	height: 636px;
	width: 347px;
	overflow-y: scroll;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.directoryListG {
	width: 347px;
	padding: 0 12px;
	margin-bottom: 16px;
	box-sizing: border-box;
}

.directoryListGTitle {
	width: 100%;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #01071A;
	line-height: 20px;
}

.directoryListGIcon {
	width: 16px;
	height: 16px;
	margin-right: 4px;
}

.directoryListC {
	padding-top: 16px;
}

.directoryListRowIndex {
	text-align: left;
	color: #8f8f96;
}

.directoryListRow:hover,
.chatRow:hover,
.seachListRow:hover {
	background: #F6F6F6;
	border-radius: 8px;

}

.directoryListRow {
	width: 100%;
	padding-left: 16px;
	border-radius: 8px;

	height: 81px;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	cursor: pointer;

	.directoryListAvatar {
		width: 44px;
		height: 44px;
		border-radius: 60px;
	}

	.directoryListName {
		white-space: nowrap;
		max-width: 220px;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-left: 8px;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #01071A;
		line-height: 22px;
	}
}

.directoryListRow_A {
	background: #F6F6F6;
}

// 聊天列表
.chatList {
	margin-top: 20px;
	height: 632px;
	width: 347px;
	overflow-y: scroll;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-bottom: 12px;
}

.chatRow {
	width: 323px;
	height: 81px;
	border-radius: 8px;
	flex-shrink: 0;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 18px 16px;
	box-sizing: border-box;
}

.chatRow_A {
	background: #F6F6F6;

}


.chatRowX {
	position: absolute;
	left: 4px;
	top: 50%;
	transform: translateY(-50%);
	width: 8px;
	height: 8px;
	border-radius: 4px;
	cursor: pointer;
	display: none;
}

.chatRowX:hover,
.chatRowX_show {
	display: inline !important;

}

.chatAvatar,
.chatRowR {
	cursor: pointer;

}

.chatAvatar {
	width: 44px;
	height: 44px;
	border-radius: 60px;
}

.chatRowR {
	width: 239px;
	height: 44px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.chatRowRTop {
	margin-bottom: 5px;

}

.chatRowRTop,
.chatRowRBottom {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.chatRowRBottom {
	min-height: 18px;
}

.chatRowName {
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 500;
	color: #01071A;
	max-width: 160px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 22px;
}

.chatRowTime {
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #8F8F96;
	display: flex;
	align-items: center;
	justify-content: center;

}

.chatRowMsg {
	max-width: 212px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 13px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #8F8F96;
	display: flex;
	align-items: center;
}

.ellipsisBox {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.chatRowStatus {
	display: flex;
	align-items: center;
}

.chatRowStatusMute {
	width: 11px;
	height: 12px;
}

.redIcon {
	width: 8px;
	height: 8px;
	border-radius: 60px;
	background-color: #FA3232;
	margin-left: 4px;
}

.unread {
	font-size: 12px;
	font-family: D-DIN-Bold, D-DIN;
	font-weight: bold;
	color: #FFFFFF;
	padding: 2px 5px;
	background: #FA3232;
	border-radius: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 8px;
}

.seachBox {
	width: 323px;
	height: 32px;
	background: #F1F2F7;
	border-radius: 16px;
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.seachInputClose {
	position: absolute;
	right: 13px;
	top: 50%;
	transform: translateY(-50%);
	width: 18px;
	height: 18px;
	cursor: pointer;
}

/deep/.el-input__inner {
	width: 323px;
	height: 32px;
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #01071A;
	line-height: 17px;
	background: #F1F2F7;
	border-radius: 16px;
	padding-right: 45px;
}

.seachIcon {
	width: 16px;
	height: 16px;
	margin-right: 2px;
}

.seachTips {
	font-size: 13px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #9D9DA5;
	line-height: 18px;
}

.chatContent {
	width: 590px;
	height: 704px;
	background: #F6F6F6;
	border-radius: 0px 20px 20px 0px;
	position: relative;
}

.noChat {
	width: 590px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.noChatIcon {
	width: 124px;
	height: 105px;
}

.chatName {
	width: 589px;
	height: 57px;
	background: #F6F6F6;
	border-radius: 0px 20px 0px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #01071A;
	line-height: 25px;
}

.chatHeng {
	width: 590px;
	height: 1px;
	background: #E9EAF1;

}

.chatMessageBox {
	width: 590px;
	height: 439px;
	display: flex;
	flex-direction: column;
	margin-left: 0 !important;
	overflow-y: scroll;
	/* background-color: #36FA; */
	padding: 0 24px ;
	box-sizing: border-box;
	position: relative;
}
.groupLaba{
	position: absolute;
	top: 58px;
	left: 24px;
	width: 542px;
	height: 72px;
	background: #FFFFFF;
	border-radius: 12px;
	padding: 12px 16px;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	cursor: pointer;
	.groupLabaIcon{
		width: 48px;
		height: 48px;
		border-radius: 60px;
		margin-right: 8px;
	}
	.groupLabaR{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 48px;
	}
	.groupLabaFromName{
		font-size: 17px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #01071A;
		line-height: 24px;
		text-align: left;
	}
	.groupLabaContent{
		max-width: 428px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #8F8F96;
		line-height: 20px;
		text-align: left;

	}
}
.exTipsBtn{
	position: absolute;
	right: 24px;
	top: 437px;
	.exTipsBtnAt{
		background: #FFFFFF;
		border-radius: 18px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FF860E;
		line-height: 20px;
		padding: 8px 26px;
		cursor: pointer;
	}
}
.newMsgBtn{
	position: absolute;
	right: 24px;
	top: 390px;
	
}
.chatMessageLockTips {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding-top: 25px;
	padding-bottom: 18px;
}

.lockIcon {
	width: 12px;
	height: 12px;
	margin-right: 7px;
}

.lockTips {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #8F8F96;
	line-height: 17px;
}

.chatMessageSystem{
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #8F8F96;
	line-height: 17px;
	text-align: center;
}

.chatMessageOther {
	display: flex;
	align-items: start;
	margin-bottom: 16px;
}

.chatMessageSelf {
	display: flex;
	align-items: start;
	margin-bottom: 16px;
	justify-content: right;
	flex-direction: row-reverse;
}

.chatMessageAvatar {
	width: 40px;
	height: 40px;
	border-radius: 60px;
}

.chatMessageOtherR {
	margin-left: 8px;
}

.chatMessageCoupon {
	width: 190px;
	height: 65px;
}
.chatMessagePicture{
	max-width: 210px;
	min-width: 50px;
	border-radius: 11px;
}
.chatMessageVoice{
	display: flex;
	align-items: center;
	cursor: pointer;
}
.chatMessageVoiceType{
	width: 20px;
	height: 20px;
	margin-right: 8px;
	border-radius: 60px;
}
.chatMessageVoiceSS{
	margin-left: 8px;
	font-size: 12px;
}
.chatMessageVoiceLine{
	display: flex;
	align-items: center;
	position: relative;
	flex-shrink:0;
}
.chatMessageVoiceLinebox{
	display: flex;
	align-items: center;
	background-color: #fff;
	flex-shrink:0;
	min-height:16px;
}
.chatMessageVoiceLineboxB{
	background-color: #3486FF;

}
.chatMessageVoiceLinebox1{
	position: absolute;
	top: 0;
	left: 0;
	overflow-x: hidden;
}
.chatMessageVoiceLineItem{
	width: 2px;
	height: 14px;
	border-radius: 1px;
	margin-right: 2px;
}
.chatMessageVoiceLinebox .chatMessageVoiceLineItem:last-child{
	margin-right: 0 !important;
}
.LineGrey{
	background: #B0B3BF;
}
.LineBlack{
	background: #01071A;
}
.LineWhite{
	background: #fff;

}
.LineGrey2{
	background: rgb(143, 188, 255);

}
.chatMessageVoiceLineNow{
	position: absolute;
	top: 50%;
	transform: translate(-50%,-50%);
	width: 2px;
	height: 45px;
	background: linear-gradient(180deg, rgba(1,7,26,0) 0%, rgba(1,7,26,0.4) 15%, #01071A 44%, rgba(1,7,26,0.4) 79%, rgba(1,7,26,0) 100%);
}
.chatMessageVoiceLineNowW{
	background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.65) 15%, #FFFFFF 44%, rgba(255,255,255,0.65) 79%, rgba(255,255,255,0) 100%);
}
.chatMessageVideoBox{
	position: relative;
}
.chatMessageVideoIcon{
	position: relative;
}
.chatMessageVideoPlay{
	cursor: pointer;
	position: absolute;
	width: 40px;
	height: 40px;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}
.chatMessageVideo{
	max-width: 264px;
	min-width: 74px;
	border-radius: 11px;
}
.chatMessageVideo{
	max-width: 264px;
	min-width: 74px;
	border-radius: 12px;
}
.chatMessageSelfL {
	margin-right: 8px;
}
.chatMessageSelfL_g{
	display: flex;
	flex-direction: column;
	align-items: end;
}

.chatMessageSelfName {
	text-align: right;
}

.chatMessageOtherName,
.chatMessageSelfName {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #8F8F96;
	line-height: 17px;
	margin-bottom: 4px;
}

.chatMessageOtherName {
	text-align: left;
}

.chatMessageOtherContent {
	background: #FFFFFF;
	border-radius: 4px 12px 12px 12px;
	font-size: 15px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #01071A;
	line-height: 24px;
	max-width: 421px;
	padding: 12px;
	box-sizing: border-box;
	text-align: left;
	word-break: break-all;
	// display: flex;
	// align-items: center;
	// flex-wrap: wrap;
	white-space: pre-wrap;
	width: max-content;

}

.chatMessageSelfContent {
	background: #3486FF;
	border-radius: 12px 4px 12px 12px;
	font-size: 15px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 24px;
	max-width: 421px;
	padding: 12px;
	box-sizing: border-box;
	text-align: left;
	word-break: break-all;
	// display: flex;
	// align-items: center;
	// flex-wrap: wrap;
	white-space: pre-wrap;
	width: max-content;
}
.chatMessageLabaTop{
	min-width: fit-content;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}
.chatMessageLaba2{
	width: 16px;
	height: 16px;
}
.chatMessageNotice{
	padding:0;
	background: #FFFFFF;
	color: #01071A;

}
.chatMessageNoticeTop{
	min-width: fit-content;
	height: 35px;
	background-color: rgb(214, 230, 255);
	color: #3486FF;
	display: flex;
	align-items: center;
	padding: 0 16px;
}
.chatMessageLaba{
	width: 14px;
	height: 14px;
	margin-right: 8px;
}
.chatMessageNoticeTopL{
	border-radius: 4px 12px 0px 0px;
}
.chatMessageNoticeTopR{
	// justify-content: end;
	border-radius: 12px 4px 0px 0px;
}
.chatMessageNoticeBot{
	padding: 12px;
}
.chatMessageLock{
	display:flex;
	align-items: center;
	// cursor: pointer;
}
.chatMessageLockIcon{
	width: 14px;
	height: 14px;
	margin-right: 3px;
}
.chatMessageLockText{
	font-size: 15px;
}






.chatReplyBox {
	width: 590px;
	height: 206px;
	background: #F6F6F6;
	border-radius: 0px 0px 20px 0px;
	/* background-color: #3296FA; */
	border-top: 1px solid #E9EAF1;
	position: relative;
}

.chatReplyTools {
	height: 47px;
	width: 590px;
	display: flex;
	padding-left: 21px;
	// padding-top: 15px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
}
.chatReplyToolsItem{
	width: 32px;
	height: 32px;
	border-radius: 8px;
	padding: 4px;
	box-sizing: border-box;
}
.chatReplyToolsItem2{
	margin-left: 2px;
}
.chatReplyToolsItem:hover{
	background: #E9E9E9;
}
.chatReplyToolsIcon {
	width: 24px;
	height: 24px;
	cursor: pointer;
}

.chatTextareaBox {
	width: 590px;
	box-sizing: border-box;
	height: 93px;
	padding: 0 27px 0 24px;

}

.chatTextarea {
	width: 539px;
	// margin-left: 27px;
	height: 93px;
	/* margin: 0 27px 0 24px;
	box-sizing: border-box; */
	font-size: 15px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #01071A;
	resize: none;
	background: #F6F6F6;
	border: none;

}


.scrollbarNo::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}


.chatShu {
	width: 1px;
	height: 704px;
	background: #E9EAF1;
}

.chatSendRow {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	padding-right: 24px;
}

.chatSendTips {
	font-size: 15px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #8F8F96;
	line-height: 21px;
	margin-right: 12px;
}

.chatSendBtn {
	font-size: 15px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #F6F6F6;
	line-height: 21px;
	width: 88px;
	height: 40px;
	background: #3486FF;

	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.UnchatText{
	background: #A0CFFE !important;
}

.scrollNew::-webkit-scrollbar {
	width: 6px !important;
	height: 6px !important;
}

.scrollNew::-webkit-scrollbar-thumb {
	border-radius: 3px !important;
	-moz-border-radius: 3px !important;
	-webkit-border-radius: 3px !important;
	background-color: #E9EAF1 !important;
}

.scrollNew::-webkit-scrollbar-track {
	background-color: transparent !important;
}

.starPchat {
	width: 590px;
	height: 704px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	.PnumberAvatar {
		width: 64px;
		height: 64px;
		border-radius: 60px;
		margin-top: 150px;
	}

	.PnumberName {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #01071A;
		line-height: 22px;
		margin: 8px 0 4px;
	}

	.PnumberID {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #8F8F96;
		line-height: 22px;
		margin-top: -2px;
	}

	.PnumberLV {
		margin: 8px 0;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 17px;
		display: flex;
		align-items: center;

		.PnumberLVNum {
			height: 16px;
			background: #FE9D33;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 5px;
		}

		.PnumberLVIconBox {
			height: 14px;

		}

		.PnumberLVIcon {
			width: 14px;
			height: 14px;
			margin-left: 4px;
		}

	}

	.PnumberSign {
		width: 348px;
		font-size: 13px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #8F8F96;
		line-height: 18px;
		text-align: center;
	}

}
.logoutBoxBtnR:hover,
.starPchatBtn:hover,
.starQchatBtn:hover,
.chatSendBtn:hover {
	background: #5FA0FF;

}

.starQchat {
	width: 590px;
	border-radius: 0px 0px 20px 0px;
	height: 646px;
	position: relative;
}

.starQchatBtn,
.starPchatBtn {
	width: 134px;
	height: 40px;
	background: #3486FF;
	border-radius: 28px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 75px;
	left: 50%;
	transform: translateX(-50%);
	cursor: pointer;
}

.QnumberBox {
	width: 590px;
	padding: 24px 37px 0;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	height: 480px;
	overflow-y: scroll;
	border-bottom: 1px #E9EAF1 solid;
}

.QnumberK {
	height: 74px;
	width: 66px;
}
.QnumberMore{
	width: 100%;
	padding-bottom: 30px;
	text-align: center;
	.QnumberMoreBtn{
		color: #3486Ff;
		font-size:12px ;
		cursor: pointer;
	}
}

.Qnumber {
	height: 74px;
	width: 66px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 32px;

	.QnumberAvatar {
		width: 48px;
		height: 48px;
		border-radius: 60px;
	}

	.QnumberName {
		font-size: 13px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #01071A;
		line-height: 18px;
		max-width: 65px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
.deleteBtnCover{
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	
	.deleteBtn{
		width: 78px;
		height: 45px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #FFFFFF;
		box-shadow: 0px 0px 20px 0px rgba(6,8,15,0.1);
		border-radius: 8px;
		font-size: 15px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #01071A;
		line-height: 21px;
		cursor: pointer;
		.deleteBtnIcon{
			width: 16px;
			height: 16px;
			margin-right: 8px;
		}
	}
}


.emojiBoxCover {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;

	.emojiBox {
		margin-left: 424px;
		margin-top: 160px;
		width: 456px;
		height: 342px;
		background: #FFFFFE;
		box-shadow: 0px 0px 24px 0px rgba(9,11,41,0.07);
		border-radius: 12px;
		background: #FFFFFF;
		padding: 16px 8px 0;
		box-sizing: border-box;
		display: flex;
		flex-wrap: wrap;
		.emoItem{
			flex-shrink:0;
			margin: 0 8px;
			height: 44px;
			cursor: pointer;
			.emoItemIcon{
				width: 28px;
				height: 28px;
			}
		}
	}
}


.userMsgBox {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;

	.userMsg {
		margin-left: 64px;
		margin-top: 24px;
		width: 255px;
		background: #FFFFFF;
		box-shadow: 0px 0px 12px 0px rgba(0, 13, 47, 0.15);
		border-radius: 12px;
		padding: 24px 24px 16px;
		box-sizing: border-box;

		.userMsgRow1 {
			display: flex;
			margin-bottom: 12px;

			.userAvatar {
				width: 44px;
				height: 44px;
				border-radius: 60px;
				margin-right: 12px;
			}

			.userMsgRow1R {
				display: flex;
				flex-direction: column;
				height: 44px;
				justify-content: space-between;

				.userName {
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #01071A;
					line-height: 22px;
					max-width: 140px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}

				.userID {
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #8F8F96;
					line-height: 17px;
				}
			}

		}

		.userMsgRow2 {
			display: flex;
			align-items: center;
			margin-bottom: 5px;

			.userMsgRow2LV {
				font-size: 12px;
				height: 16px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 16px;
				background: #FE9D33;
				border-radius: 8px;
				padding: 0 8px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.userMsgRow2LVIconBox {
				margin-left: 4px;

				.userMsgRow2LVIcon {
					width: 14px;
					height: 14px;
					margin-right: 3px;
				}
			}
		}

		.userMsgRow3 {
			width: 218px;
			font-size: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #8F8F96;
			line-height: 17px;
			text-align: left;
		}

		.logoutBtn {
			width: 207px;
			height: 34px;
			border-radius: 17px;
			border: 1px solid #EBEBEB;
			margin-top: auto;
			font-size: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #68686E;
			line-height: 17px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 16px 0 0;
			cursor: pointer;
		}
	}
}

.audioBox{
	position: fixed;
	top: 500vh;
	left: 500vh;


}

.videoBox{
	position: fixed;
	top: 0;
    right: 0;
    bottom: 0;
    left: 0;
	z-index: 2000;
	.videoCover{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: .5;
		background: #000;
	}
	.videoView{
		position: relative;
		z-index: 2001;

		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		.videoItem{
			max-width: 85%;
			max-height: 85%;
		}
	}
}
.logoutBoxCover{
	position: fixed;
	z-index: 99999;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0,0,0,0.5);
}
.logoutBox{
	width: 491px;
	height: 267px;
	background: #FFFFFF;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.logoutBoxTitle{
	font-size: 20px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #01071A;
	line-height: 28px;
	letter-spacing: 1px;
	margin-top: 48px;
	margin-bottom: 8px;

}
.logoutBoxTitleS{
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #8F8F96;
	line-height: 22px;
	margin-bottom: 65px;
}
.logoutBoxBtns{
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	line-height: 25px;
}

.logoutBoxBtnL{
	width: 156px;
	height: 48px;
	border-radius: 28px;
	border: 1px solid #E9EAF1;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #8F8F96;
	margin: 0 20px;
	cursor: pointer;
}
.logoutBoxBtnR{
	width: 156px;
	height: 48px;
	background: #3486FF;
	border-radius: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #FFFFFF;
	margin: 0 20px;
	cursor: pointer;

}
.qrcanvasBox{
	position:fixed;
	top: 10000vh;
	left: 10000vw;
}

</style>


<style >

.el-image-viewer__wrapper .el-image-viewer__img{
	max-width: 85% !important;
	max-height: 85% !important;
}

.el-image-viewer__close {
	display: none !important;
}

</style>
















